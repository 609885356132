import React from 'react';

import styled from 'styled-components';

import { Flex, Spinner, Text } from 'combinezone/core';
import { SelectItem } from 'combinezone/core/Select';
import { CombinezoneTheme } from 'combinezone/theme';

export const FormElementWrapper = styled(Flex)`
  gap: 8px;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
`;

export const FormElementCheckboxWrapper = styled(Flex)`
  gap: 8px;
  justify-content: start;
  align-items: center;
  flex: 1;
`;

export const FormElementError = styled(Text)`
  font-size: 14px;
  color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.components.input.colors.border.critical};
`;

export const TemplateForm = styled.form`
  width: 100% !important;
`;

export const optionItemWithSpinner: SelectItem = {
  value: '',
  content: <Spinner size="sm" />,
  testId: 'spinner',
};
