import { RSAA } from 'redux-api-middleware';

import { withHeaders } from '..';

import {
  CLEAR_NOTIFICATION,
  SENT_BY_EMAIL_FAIL,
  SENT_BY_EMAIL_REQUEST,
  SENT_BY_EMAIL_SUCCESS,
} from './actionTypes';

export const clearedNotification = () => ({
  type: CLEAR_NOTIFICATION,
});

export const sentByEmailCsv = (endpoint) => ({
  [RSAA]: {
    endpoint,
    method: 'GET',
    headers: withHeaders({ 'Content-Type': 'application/json' }),
    types: [SENT_BY_EMAIL_REQUEST, SENT_BY_EMAIL_SUCCESS, SENT_BY_EMAIL_FAIL],
  },
});
