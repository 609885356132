import { I18n } from 'react-redux-i18n';

import { getEndOfDay, getStartOfDay, getSubDays, getUnix } from '@soc/kit/date';
import { message } from 'antd';

import { encodeIncsChangesFilter, INCIDENTS_ENDPOINT } from './api';
import { apiFetch } from './apiFetch';

class IncidentMessagesService {
  updateTimer = null;

  updateInterval = 30 * 1000;

  mutedIncidents = [];

  start = () => {
    this.lastUpdateTime = new Date();
    this.stop();
    this.updateTimer = setInterval(() => this.main(), this.updateInterval);
  };

  main = () => {
    const lastReceivedIncTimestamp = `${getUnix(this.lastUpdateTime) * 1000}`;
    const startTimestamp = `${
      getUnix(getStartOfDay(getSubDays(new Date(), 1))) * 1000
    }`;
    const endTimestamp = `${getUnix(getEndOfDay(new Date()) * 1000)}`;

    const incidentParams = encodeIncsChangesFilter(
      {},
      startTimestamp,
      endTimestamp,
      lastReceivedIncTimestamp,
    );

    this.fetchIncidents(incidentParams).then((body) => {
      for (const incident of body.results) {
        const mutedIncidentIndex = this.mutedIncidents.indexOf(incident.key);
        if (mutedIncidentIndex === -1) {
          message.warning(
            I18n.t('newIncidentMessage.message', { key: incident.key }),
          );
        } else {
          this.mutedIncidents.slice(mutedIncidentIndex, 1);
        }
      }

      this.lastUpdateTime = new Date();
    });
  };

  fetchIncidents = (params) =>
    apiFetch.get(`${INCIDENTS_ENDPOINT}${params}`, {});

  stop = () => clearInterval(this.updateTimer);
}

export default new IncidentMessagesService();
