import { useCallback, useEffect, useState } from 'react';

import { abortApiFetch, isAbortError, useApiToastError } from '@soc/kit/api';

import { fetchStatusActions } from '../actions/fetchStatusActions';
import { statusActionsFetchAbortControllerKey } from '../constants';
import { StatusActions } from '../enums';
import { UseStatusActions } from '../types';

export const useStatusActions: UseStatusActions = ({ incidentId }) => {
  const apiToastError = useApiToastError();
  const [data, setData] = useState<StatusActions[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isRefresh, setIsRefresh] = useState({});

  const getStatusActions = useCallback(() => {
    setIsFetching(true);

    fetchStatusActions({ incidentId })
      .then((res) => {
        setData(res.results);
        setIsFetching(false);
      })
      .catch((error: Error) => {
        apiToastError(error);
        setData([]);

        if (isAbortError(error as unknown as Record<string, unknown>)) {
          return;
        }

        setIsFetching(false);
      });
  }, [incidentId, isRefresh]);

  useEffect(() => {
    getStatusActions();

    return () => {
      abortApiFetch(statusActionsFetchAbortControllerKey);
      setData([]);
    };
  }, [incidentId, isRefresh]);

  return {
    data,
    isFetching,
    setIsRefresh,
  };
};
