import { BaseSelectOption } from 'combinezone/core';

import { IssueTypeItem } from './types';

export const convertIssueTypesToOptions = (
  data: IssueTypeItem[],
): BaseSelectOption[] => {
  if (!data.length) {
    return [];
  }

  return data.map(({ id, title
   }) => ({
    value: id,
    content: title,
    testId: `${id}`,
  }));
};
