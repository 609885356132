import React, { useRef } from 'react';

import DefaultPanel from '../DefaultPanel';
import { PanelProps } from '../types';
import { useResize } from '../useResize';

const changeSize = (
  resizableContainerElement: any,
  width: any,
  offset: any,
  min: any,
  max: any,
) => {
  const newValue = width - offset;
  if (newValue > min && newValue < max) {
    resizableContainerElement.style['flex-basis'] = `${newValue}px`;
    return newValue;
  }
  return width;
};

export default function ResizablePanel<Entity>(props: PanelProps<Entity>) {
  const panelRef = useRef(null);
  const resizeMakerRef = useRef(null);

  const { isResizingWidth, width } = useResize({
    resizableContainerElement: panelRef.current,
    draggableElement: resizeMakerRef.current,
    changeSize,
  });

  return (
    <DefaultPanel<Entity>
      width={width}
      isResizingWidth={isResizingWidth}
      panelRef={panelRef}
      resizeMakerRef={resizeMakerRef}
      {...props}
    />
  );
}
