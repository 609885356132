import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { Checkbox, Text } from 'combinezone/core';
import { useTranslate } from 'combinezone/utils';

import { FormElementCheckboxWrapper, FormElementError } from '../..';

import locales from './locales';
import { AssignToMyselfProps } from './types';

const AssignToMyselfFormElement: FC<AssignToMyselfProps> = ({
  control,
  error,
  testId,
}) => {
  const t = useTranslate(locales);
  const assignToMyselfCheckboxFormElementTestId = `${testId}-form-element-checkbox-assign-to-myself`;

  return (
    <FormElementCheckboxWrapper
      fillWidth
      direction="row"
      testId={`${assignToMyselfCheckboxFormElementTestId}-wrapper`}
    >
      <Controller
        name="assignToMyself"
        control={control}
        render={({ field }) => (
          <Checkbox
            name={field.name}
            onChange={field.onChange}
            isChecked={!!field.value}
            testId={`${assignToMyselfCheckboxFormElementTestId}-checkbox`}
          />
        )}
      />

      <Text testId={`${assignToMyselfCheckboxFormElementTestId}-title`}>
        {t('assignToMyself')}
      </Text>

      {error && (
        <FormElementError
          testId={`${assignToMyselfCheckboxFormElementTestId}-error`}
        >
          {error.message}
        </FormElementError>
      )}
    </FormElementCheckboxWrapper>
  );
};

export default AssignToMyselfFormElement;

AssignToMyselfFormElement.displayName = 'AssignToMyselfFormElement';
