import { api, SOC_URL } from '@soc/kit/api';

import { ConfirmActionFetch } from '../types';

export const fetchConfirmAction: ConfirmActionFetch = ({
  assignToMyself,
  comment,
  incidentId,
}) => {
  const body = JSON.stringify({
    change_customer_assignee: assignToMyself,
    comment,
  });

  return api<unknown>(`${SOC_URL}incs/${incidentId}/confirm/`, {
    method: 'post',
    body,
  });
};
