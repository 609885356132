import { createAction } from 'redux-api-middleware';

import { instructionsNextPage, withHeaders, getFilterParams } from '..';
import {
  INSTRUCTIONS_ENDPOINT,
  INSTRUCTIONS_ENTITY,
} from '../../../services/api';

import * as types from './actionTypes';

export const firstFetch = () => {
  const timestamp = Date.now();

  return (dispatch, getState) => {
    const params = getFilterParams({
      state: getState(),
      entity: INSTRUCTIONS_ENTITY,
    });

    return dispatch(
      createAction({
        endpoint: `${INSTRUCTIONS_ENDPOINT}${params}`,
        method: 'GET',
        headers: withHeaders({ 'Content-Type': 'application/json' }),
        types: [
          {
            type: types.FIRST_FETCH_REQUEST,
            meta: { timestamp },
          },
          {
            type: types.FIRST_FETCH_SUCCESS,
            meta: { timestamp },
          },
          {
            type: types.FIRST_FETCH_FAIL,
            meta: { timestamp },
          },
        ],
      }),
    );
  };
};

export const fetchMore = () => (dispatch, getState) => {
  const state = getState();
  const page = instructionsNextPage(state);
  const { lastFetchTime } = state.instructions;
  const params = getFilterParams({
    state: getState(),
    entity: INSTRUCTIONS_ENTITY,
  });

  return dispatch(
    createAction({
      endpoint: `${INSTRUCTIONS_ENDPOINT}${params}&page=${page}`,
      method: 'GET',
      headers: withHeaders({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_MORE_REQUEST,
        {
          type: types.FETCH_MORE_SUCCESS,
          meta: { timestamp: lastFetchTime },
        },
        {
          type: types.FETCH_MORE_FAIL,
          meta: { showNotification: false },
        },
      ],
    }),
  );
};

export const select = (instruction = {}) => ({
  type: types.SELECT_INSTRUCTION,
  meta: { instruction },
});
