import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button, ButtonPrimary, Flex, Modal } from 'combinezone/core';
import { useTranslate } from 'combinezone/utils';

import { TemplateForm } from '../../shared';
import AssignToMyselfFormElement from '../../shared/components/AssignToMyselfFormElement';
import CommentWisiwygFormElement from '../../shared/components/CommentWisiwygFormElement';

import { useSetConfirmAction } from './hooks/useSetConfirmAction';
import locales from './locales';
import { ConfirmActionModalForm, ConfirmActionModalProps } from './types';

const ConfirmActionModal: FC<ConfirmActionModalProps> = ({
  incidentId,
  onClose,
  setIsRefresh,
  testId,
  updateFunction,
}) => {
  const t = useTranslate(locales);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<ConfirmActionModalForm>();
  const { isFetching, setNewComment } = useSetConfirmAction({
    onClose,
    setError,
    setIsRefresh,
    updateFunction,
  });
  const confirmActionModalTestId = `${testId}-confirm-action-modal`;

  const onCloseClickHandler = (): void => {
    onClose(false);
  };

  const onSubmit: SubmitHandler<ConfirmActionModalForm> = (
    formData,
    event,
  ): void => {
    if (event) {
      return;
    }

    setNewComment({ ...formData, incidentId });
  };

  const onSubmitClickHandler = async (): Promise<void> => {
    await handleSubmit(onSubmit)();
  };

  const content = (
    <TemplateForm onSubmit={handleSubmit(onSubmit)}>
      <Flex gap="24px" direction="column">
        <CommentWisiwygFormElement
          control={control}
          error={errors.comment}
          testId={confirmActionModalTestId}
          placeholder={t('commentPlaceholder')}
        />

        <AssignToMyselfFormElement
          testId={confirmActionModalTestId}
          control={control}
          error={errors.assignToMyself}
        />
      </Flex>
    </TemplateForm>
  );

  const footerContent = (
    <Flex gap="10px">
      <ButtonPrimary
        testId={`${confirmActionModalTestId}-button-create`}
        onClick={onSubmitClickHandler}
        isLoading={isFetching}
        disabled={isFetching}
      >
        {t('createButton')}
      </ButtonPrimary>
      <Button
        testId={`${confirmActionModalTestId}-button-close`}
        onClick={onCloseClickHandler}
      >
        {t('cancelButton')}
      </Button>
    </Flex>
  );

  return (
    <Modal
      testId={confirmActionModalTestId}
      title={t('title')}
      content={content}
      footerContent={footerContent}
      onClose={onCloseClickHandler}
      focusLockProps={{
        disabled: true,
      }}
    />
  );
};
export default ConfirmActionModal;

ConfirmActionModal.displayName = 'ConfirmActionModal';
