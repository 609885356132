import { apiFetch } from '@common/soc-react-kit';

import store from '../store';
import { withHeaders } from '../store/reducers';

const { dispatch } = store;

apiFetch.initialize({
  dispatch,
  withAuth: withHeaders,
});

export { apiFetch };
