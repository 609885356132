import { getMomentObject } from '@common/soc-react-kit/src/utils/momentHelpers';
import widgets from '../../../services/widgets';

import * as types from './actionTypes';

const omit = (obj, key) => {
  const { [key]: omitted, ...rest } = obj;
  return rest;
};

const initialState = {
  analytics: {
    fetching: true,
    timestamp: null,
    data: {},
  },
  updateTimer: null,
  updateInterval: 30 * 1000,
  lastUpdateTime: getMomentObject(new Date()),
  layout: [],
  cols: 16,
  widgets: {},
  isChartFontsLoaded: false,
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_ANALYTICS_REQUEST:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          timestamp: action.meta.timestamp,
          fetching: !action.meta.isUpdate,
        },
      };

    case types.FETCH_ANALYTICS_SUCCESS:
      if (state.analytics.timestamp > action.meta.timestamp) {
        return state;
      }

      return {
        ...state,
        lastUpdateTime: getMomentObject(action.meta.timestamp),
        analytics: {
          ...state.analytics,
          fetching: false,
          data: action.payload,
        },
      };

    case types.FETCH_ANALYTICS_FAILURE:
      if (state.analytics.timestamp > action.meta.timestamp) {
        return state;
      }

      return {
        ...state,
        analytics: {
          ...state.analytics,
          fetching: false,
        },
      };

    case types.SET_DASHBOARD_UPDATE_TIMER:
      return {
        ...state,
        updateTimer: action.payload.newUpdateTimer,
      };

    case types.UNSET_DASHBOARD_UPDATE_TIMER:
      return {
        ...state,
        updateTimer: null,
      };

    case types.GET_DASHBOARD:
      return {
        ...state,
        layout: action.payload.layout,
        widgets: action.payload.widgets,
      };

    case types.EDIT_DASHBOARD_SET_GRID_LAYOUT:
      return {
        ...state,
        layout: action.payload.layout,
      };

    case types.DASHBOARD_WIDGETS_MENU_CHECKBOX_CLICK:
      const { checkedValue, widgetId } = action.payload;
      if (checkedValue) {
        const { layout, widget } = widgets.placeWidget(
          state.layout,
          state.cols,
          widgetId,
        );
        return {
          ...state,
          layout: state.layout.concat(layout),
          widgets: {
            ...state.widgets,
            [widget.id]: widget,
          },
        };
      }

      return {
        ...state,
        widgets: omit(state.widgets, widgetId),
        layout: state.layout.filter((widget) => widget.i !== widgetId),
      };

    case types.DELETE_WIDGET:
      const deletedWidgetId = action.payload;
      return {
        ...state,
        widgets: omit(state.widgets, deletedWidgetId),
        layout: state.layout.filter((widget) => widget.i !== deletedWidgetId),
      };

    case types.EDIT_WIDGET_MENU_SUBMIT:
      const { widgetSettings } = action.payload;
      const editedWidgetId = action.payload.widgetId;
      const editedWidget = {
        ...state.widgets[editedWidgetId],
        ...widgetSettings,
      };
      return {
        ...state,
        widgets: {
          ...state.widgets,
          [editedWidgetId]: editedWidget,
        },
        chartjsWidgetToRedraw: editedWidgetId,
      };

    case types.CHART_FONTS_LOADING_SUCCESS:
      return {
        ...state,
        isChartFontsLoaded: true,
      };

    default:
      return state;
  }
}

export const getAnalytics = (state) => state.analytics.data;
export const getAnalyticsFetching = (state) => state.analytics.fetching;
export const getMitre = (state) => state.analytics.data.mitre;
export const getIsChartFontsLoaded = (state) => state.isChartFontsLoaded;
