import React, { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { Select, Text } from 'combinezone/core';
import { useDebouncedCallback, useTranslate } from 'combinezone/utils';

import {
  FormElementError,
  FormElementWrapper,
  optionItemWithSpinner,
} from '../../../../shared';

import { useResolutions } from './hooks/useResolutions';
import locales from './locales';
import { ResolutionSelectFormElementProps } from './types';

const ResolutionSelectFormElement: FC<ResolutionSelectFormElementProps> = ({
  control,
  error,
  issueType,
  testId,
}) => {
  const t = useTranslate(locales);
  const resolutionInputFormElementTestId = `${testId}-form-element-select-resolution`;

  const { data, isFetching, setInlineSearch, setIsOpen } = useResolutions({
    issueType,
  });

  const systemsSearchHandler = useDebouncedCallback((text: string): void => {
    setInlineSearch(text);
  }, 300);

  const supplementedOptions = useMemo(() => {
    return isFetching ? [optionItemWithSpinner] : data;
  }, [isFetching, data]);

  return (
    <FormElementWrapper testId={`${resolutionInputFormElementTestId}-wrapper`}>
      <Text testId={`${resolutionInputFormElementTestId}-title`}>
        {t('resolution')}
      </Text>

      <Controller
        name="resolution"
        control={control}
        rules={{
          required: t('requiredField'),
        }}
        render={({ field }) => (
          <Select
            {...field}
            testId={`${testId}-resolution-select`}
            error={!!error}
            multiple={false}
            options={supplementedOptions}
            placeholder={t('resolutionPlaceholder')}
            onSearchStringChange={systemsSearchHandler}
            dropdownProps={{
              onOpen: () => setIsOpen(true),
              onClose: () => setIsOpen(false),
            }}
          />
        )}
      />

      {error && (
        <FormElementError testId={`${resolutionInputFormElementTestId}-error`}>
          {error.message}
        </FormElementError>
      )}
    </FormElementWrapper>
  );
};
export default ResolutionSelectFormElement;

ResolutionSelectFormElement.displayName = 'ResolutionSelectFormElement';
