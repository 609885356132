import { AnyAction } from 'redux';
import { createAction } from 'redux-api-middleware';
import { ThunkAction } from 'redux-thunk';

import { INCIDENTS_ENDPOINT } from 'services/api';
import { getCmdbAssetPageIncidentsNext, withHeaders } from 'store/reducers';

import {
  FetchAssetCardIncidentsFailureType,
  FetchAssetCardIncidentsSuccessType,
  FetchAssetCardMoreIncidentsFailureType,
  FetchAssetCardMoreIncidentsSuccessType,
} from './actionMatchTypes';
import * as types from './actionTypes';

export type ThunkActionType<T> = ThunkAction<T, any, unknown, AnyAction>;

export type FetchIncidentsType = (
  cmdbAssetId: string,
) => ThunkActionType<
  Promise<
    FetchAssetCardIncidentsSuccessType | FetchAssetCardIncidentsFailureType
  >
>;

export const fetchIncidents: FetchIncidentsType =
  (cmdbAssetId: string) => (dispatch) => {
    const action = createAction({
      endpoint: `${INCIDENTS_ENDPOINT}?ql=snapshots.cmdb_id = "${cmdbAssetId}"`,
      method: 'GET',
      headers: withHeaders({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_ASSET_CARD_INCIDENTS_REQUEST,
        types.FETCH_ASSET_CARD_INCIDENTS_SUCCESS,
        types.FETCH_ASSET_CARD_INCIDENTS_FAILURE,
      ],
    });

    return dispatch(action);
  };

export type FetchMoreIncidentsType = ThunkActionType<
  Promise<
    | FetchAssetCardMoreIncidentsSuccessType
    | FetchAssetCardMoreIncidentsFailureType
  >
>;

export const fetchMoreIncidents: FetchMoreIncidentsType = (
  dispatch,
  getState,
) => {
  const next = getCmdbAssetPageIncidentsNext(getState());
  if (next) {
    const action = createAction({
      endpoint: next,
      method: 'GET',
      headers: withHeaders({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_ASSET_CARD_MORE_INCIDENTS_REQUEST,
        types.FETCH_ASSET_CARD_MORE_INCIDENTS_SUCCESS,
        types.FETCH_ASSET_CARD_MORE_INCIDENTS_FAILURE,
      ],
    });
    return dispatch(action);
  }
};
