import React from 'react';

import { PanelProps } from './types';

import './Panel.scss';
import DefaultPanel from './DefaultPanel';
import ResizablePanel from './ResizablePanel';

export default function Panel<Entity>({
  resizable,
  ...rest
}: PanelProps<Entity>) {
  return resizable ? (
    <ResizablePanel<Entity> resizable={resizable} {...rest} />
  ) : (
    <DefaultPanel<Entity> {...rest} />
  );
}
