import React, { FC, useEffect, useState } from 'react';

import { PseudoLink } from 'combinezone/core';
import { useTranslate } from 'combinezone/utils';

import locales from './locales';
import { ActionLinkProps } from './types';

const ActionLink: FC<ActionLinkProps> = ({
  data,
  incidentId,
  isFetching,
  issueType,
  modal: Modal,
  setIsRefresh,
  status,
  testId,
  updateFunction,
}) => {
  const t = useTranslate(locales);
  const [isViewActionLink, setIsViewActionLink] = useState(false);
  const [isViewActionModal, setIsViewActionModal] = useState(false);
  const statusActionsTestId = `${testId}-${status}`;

  useEffect(() => {
    setIsViewActionLink(false);

    data.forEach((elem) => {
      if (elem === status) {
        setIsViewActionLink(true);
      }
    });
  }, [data, status]);

  const onLinkClickHandler = (): void => {
    setIsViewActionModal(true);
  };

  return (
    <>
      {!isFetching && isViewActionLink && (
        <PseudoLink
          onClick={onLinkClickHandler}
          testId={`${statusActionsTestId}-link}`}
        >
          {t(status)}
        </PseudoLink>
      )}

      {isViewActionModal && (
        <Modal
          incidentId={incidentId}
          issueType={issueType}
          testId={statusActionsTestId}
          onClose={setIsViewActionModal}
          setIsRefresh={setIsRefresh}
          updateFunction={updateFunction}
        />
      )}
    </>
  );
};
export default ActionLink;

ActionLink.displayName = 'ActionLink';
