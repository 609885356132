import * as React from 'react';
import { Translate, I18n } from 'react-redux-i18n';

import styled from 'styled-components';

import { Button } from '@bizone/ui-bundle/esm/Button';
import { Form } from '@bizone/ui-bundle/esm/Form';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { Icon } from '@bizone/ui-bundle/esm/Icon';
import { Input } from '@bizone/ui-bundle/esm/Input';
import { Select } from '@bizone/ui-bundle/esm/Select';
import { Textarea } from '@bizone/ui-bundle/esm/Textarea';

import { FocusButton } from '@common/soc-react-kit';
import { Modal, notification } from 'antd';
import { Text, Flex, Datepicker, RangeDatepicker } from 'combinezone/core';
import { DatepickerContainer } from 'combinezone/core/Datepicker/Datepicker';

import bizoneNotification from '../Notification';

import './CreateReport.scss';
import {
  getDateInFullDateAndTimeFormat,
  getEndOfMonth,
  getFormat,
  getStartOfMonth,
  getSubDays,
  momentFiltersFormat,
} from '@soc/kit/date';
import { momentFormat } from '@soc/kit/date/constants';

const CustomDatepicker = styled(RangeDatepicker)`
  ${DatepickerContainer} {
    width: 100%;
  }
`;

const DateContainer = styled(Flex)`
  width: 250px;
`;

const dropdownProps = {
  usePortal: false,
};

export default class CreateReport extends React.Component {
  state = {
    start: '',
    rangePickerValue: [],
    choosedPeriod: false,
    systemList: [],
    periodicity: '',
  };

  onChangeField = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  onChangeStartTime = (e) => {
    this.setState({
      start: new Date(e),
    });
  };

  onChangeRangePicker = (e) => {
    this.setState({
      rangePickerValue: [new Date(e[0]), new Date(e[1])],
    });
  };

  onChangePeriodicity = (value) => {
    if (value === 'forPeriod') {
      this.setState({
        start: '',
        choosedPeriod: true,
        periodicity: value,
      });
    } else {
      this.setState({
        rangePickerValue: [],
        choosedPeriod: false,
        periodicity: value,
      });
    }
  };

  handleCreate = () => {
    const {
      values: { emails, periodicity, reportName, systemList },
    } = this.formRef;

    const systemsKeys = systemList.map((system) => system.key);
    const systemsText = systemList.map((system) => system.text);
    if (this.state.choosedPeriod) {
      const { createReport } = this.props;
      const period = this.state.rangePickerValue;

      const [yearStart, monthStart, dayStart] = getFormat(
        period[0],
        momentFiltersFormat,
      )
        .split(' ')[0]
        .split('-');

      const [yearFinish, monthFinish, dayFinish] = getFormat(
        period[1],
        momentFiltersFormat,
      )
        .split(' ')[0]
        .split('-');

      const start = `${dayStart}.${monthStart}.${yearStart}`;
      const finish = `${dayFinish}.${monthFinish}.${yearFinish}`;

      const emailsArray = emails.split(', ').map((email) => email.trim());

      createReport({
        name: reportName,
        systems: systemsText,
        emails: emailsArray,
        start,
        finish,
      }).then((result) => {
        if (result.error) {
          this.onError(result.payload.response?.__all__[0]);
        } else {
          this.onOk(reportName);
        }
      });
    } else {
      const { createPeriodAndCrontab, createReportTask } = this.props;
      const { start } = this.state;
      const { crontab, period } = createPeriodAndCrontab(periodicity, start);
      const startTime = getFormat(start, momentFormat).slice(0, 16);
      const emailsArray = emails.split(', ').map((email) => email.trim());
      createReportTask({
        name: reportName,
        systems: systemsKeys,
        emails: emailsArray,
        start_time: startTime,
        period,
        crontab,
      }).then((result) => {
        if (result.error) {
          this.onError(result.payload.response);
        } else {
          this.onOk(reportName);
        }
      });
    }
  };

  onOk = (reportName) => {
    this.onModalCancel();
    if (this.state.choosedPeriod) {
      bizoneNotification.success({
        message: `${I18n.t(
          'createReport.createConfirm.report',
        )}${reportName}${I18n.t('createReport.createConfirm.willBeGenerated')}`,
      });
    } else {
      bizoneNotification.success({
        message: `${I18n.t(
          'createReport.createConfirm.settings',
        )}${reportName}${I18n.t('createReport.createConfirm.saved')}`,
      });
    }
  };

  onError = (error) => {
    notification.error({ message: error });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  onModalCancel = () => {
    this.props.onCancel();
    this.formRef.form.reset();
    this.setState({
      start: '',
      rangePickerValue: [],
      choosedPeriod: false,
      systemList: [],
      periodicity: '',
    });
  };

  render() {
    const { isLoading, isOpen, locale, systems } = this.props;

    const systemsList = systems.map((system) => ({
      content: system.text,
      value: system,
    }));

    const periodsList = [
      {
        content: I18n.t('createReport.periodicity.everyDay'),
        value: 'everyDay',
      },
      {
        content: I18n.t('createReport.periodicity.everyWeek'),
        value: 'everyWeek',
      },
      {
        content: I18n.t('createReport.periodicity.twiceAMonth'),
        value: 'twiceAMonth',
      },
      {
        content: I18n.t('createReport.periodicity.onceAMonth'),
        value: 'onceAMonth',
      },
      {
        content: I18n.t('createReport.periodicity.forPeriod'),
        value: 'forPeriod',
      },
    ];

    const initialValues = {
      systemList: this.state.systemList,
      reportName: this.state.reportName,
      periodicity: this.state.periodicity,
      emails: this.state.emails,
    };

    return (
      <Form
        initialValues={initialValues}
        cls="createReport"
        validate={() => {
          if (
            (this.state.choosedPeriod &&
              this.state.rangePickerValue.length === 0) ||
            (!this.state.choosedPeriod && !this.state.start)
          ) {
            return { dateError: 'Поле даты не заполненно' };
          }
          if (
            !this.state.emails
              ?.split(',')
              .map((email) => email.trim())
              .every(this.props.validateEmail)
          ) {
            return { emailError: 'Некорректный email' };
          }
          if (!this.state.reportName) {
            return { nameError: 'Некорректное имя' };
          }
        }}
      >
        {(form) => {
          this.saveFormRef(form);

          const {
            hasSubmitErrors,
            hasValidationErrors,
            submitErrors,
            validating,
            values,
          } = form;
          const isInvalid =
            hasValidationErrors ||
            (hasSubmitErrors &&
              !Object.keys(submitErrors).every(
                (key) => form.form.getFieldState(key).dirtySinceLastSubmit,
              ));
          const frequency = I18n.t(`createReport.info.${values.periodicity}`);

          return (
            <React.Fragment>
              {React.cloneElement(this.props.button, {
                onClick: this.props.onOpen,
              })}
              <Modal
                title={
                  <Header size={20}>{I18n.t('createReport.newReport')}</Header>
                }
                visible={isOpen}
                onCancel={this.onModalCancel}
                className="CreateReportModal"
                closeIcon={
                  <div className="Modal-CloseIcon">
                    <Icon glyph="close" size={24} />
                  </div>
                }
                footer={[
                  <FocusButton
                    key="submit"
                    rich
                    primary
                    disabled={validating || isInvalid}
                    loading={isLoading}
                    onClick={this.handleCreate}
                  >
                    <Translate
                      value={
                        this.state.choosedPeriod
                          ? I18n.t('createReport.title')
                          : I18n.t('createReport.save')
                      }
                    />
                  </FocusButton>,
                  <Button key="back" onClick={this.onModalCancel}>
                    <Translate value="createReport.cancelButton" />
                  </Button>,
                ]}
                width={720}
                bodyStyle={{
                  padding: '32px 56px 45px 56px',
                }}
              >
                <React.Fragment>
                  <Form.Item
                    required
                    requiredTitle={I18n.t(
                      'createReport.requiredTitles.reportName',
                    )}
                    label={I18n.t('createReport.reportname')}
                    name="reportName"
                    onChange={(value) =>
                      this.onChangeField(value, 'reportName')
                    }
                  >
                    <Input
                      disabled={isLoading}
                      placeholder={I18n.t('createReport.placeholders.title')}
                    />
                  </Form.Item>
                  <Form.Item
                    required
                    label={I18n.t('createReport.system')}
                    name="systemList"
                    onChange={(value) =>
                      this.onChangeField(value, 'systemList')
                    }
                  >
                    <Select
                      multiple
                      content={[]}
                      options={systemsList || []}
                      disabled={isLoading}
                      placeholder={I18n.t('createReport.placeholders.system')}
                    />
                  </Form.Item>
                  <div className="Periodicity">
                    <Form.Item
                      required
                      label={I18n.t('createReport.periodicity.label')}
                      name="periodicity"
                      onChange={(value) => this.onChangePeriodicity(value)}
                    >
                      <Select
                        options={periodsList}
                        disabled={isLoading}
                        placeholder={I18n.t(
                          'createReport.placeholders.periodicity',
                        )}
                      />
                    </Form.Item>
                    <Flex direction="column" className="DatePickerItem">
                      <Text className="DatePickerItem-Label">
                        {I18n.t('createReport.periodicity.startLabel')}
                      </Text>
                      <DateContainer>
                        {this.state.choosedPeriod ? (
                          <CustomDatepicker
                            dropdownProps={dropdownProps}
                            testId="Report_Create_RangeDatepicker"
                            autoSubmit={false}
                            value={[
                              this.state.rangePickerValue[0],
                              this.state.rangePickerValue[1],
                            ]}
                            onChange={this.onChangeRangePicker}
                            size="medium"
                            presets={[
                              {
                                title: I18n.t('dashboard.last7days'),
                                dateValue: [
                                  getSubDays(new Date(), 6),
                                  new Date(),
                                ],
                              },
                              {
                                title: I18n.t('dashboard.last30days'),
                                dateValue: [
                                  getSubDays(new Date(), 29),
                                  new Date(),
                                ],
                              },
                              {
                                title: I18n.t('dashboard.thisMonth'),
                                dateValue: [
                                  getStartOfMonth(new Date()),
                                  new Date(),
                                ],
                              },
                              {
                                title: I18n.t('dashboard.lastMonth'),
                                dateValue: [
                                  getStartOfMonth(getSubDays(new Date(), 29)),
                                  getEndOfMonth(getSubDays(new Date(), 29)),
                                ],
                              },
                            ]}
                          />
                        ) : (
                          <Datepicker
                            value={this.state.start}
                            testId="Report_Create_Datepicker"
                            onChange={this.onChangeStartTime}
                            isTimepickerEnabled
                            dropdownProps={dropdownProps}
                            size="medium"
                          />
                        )}
                      </DateContainer>
                    </Flex>
                  </div>
                  {values.periodicity &&
                    (this.state.start ||
                      this.state.rangePickerValue.length > 0) && (
                      <>
                        <p className="Info">
                          <Icon glyph="error" size={32} />
                          <Text className="Text">
                            {values.periodicity === 'forPeriod'
                              ? `${I18n.t(
                                  'createReport.info.oneReport',
                                )}${getDateInFullDateAndTimeFormat(
                                  locale,
                                  this.state.rangePickerValue[0].toString(),
                                )} ${I18n.t(
                                  'createReport.info.to',
                                )} ${getDateInFullDateAndTimeFormat(
                                  locale,
                                  this.state.rangePickerValue[1].toString(),
                                )}.`
                              : `${I18n.t(
                                  'createReport.info.reports',
                                )}${getDateInFullDateAndTimeFormat(
                                  locale,
                                  this.state.start.toString(),
                                )} ${frequency}. ${I18n.t(
                                  'createReport.info.clarification',
                                )}`}
                          </Text>
                        </p>
                        <div>
                          <Form.Item
                            required
                            label={I18n.t('createReport.email')}
                            name="emails"
                            requiredTitle={I18n.t(
                              'createReport.requiredTitles.emails',
                            )}
                            onChange={(value) =>
                              this.onChangeField(value, 'emails')
                            }
                          >
                            <Textarea
                              disabled={isLoading}
                              placeholder={I18n.t(
                                'createReport.placeholders.email',
                              )}
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}
                </React.Fragment>
              </Modal>
            </React.Fragment>
          );
        }}
      </Form>
    );
  }
}
