import { useCallback, useEffect, useState } from 'react';

import { isAbortError } from '@soc/kit/api';
import { useToast } from 'combinezone/core';
import { useTranslate } from 'combinezone/utils';

import { fetchReopenAction } from '../actions/fetchReopenAction';
import { backendNamesToFormNamesMapping } from '../constants';
import locales from '../locales';
import { ReopenActionFetchProps, UseReopenAction } from '../types';

export const useSetReopenAction: UseReopenAction = ({
  onClose,
  setError,
  setIsRefresh,
  updateFunction,
}) => {
  const { toastError, toastSuccess } = useToast();
  const t = useTranslate(locales);
  const [isFetching, setIsFetching] = useState(false);
  const [newComment, setNewComment] = useState<ReopenActionFetchProps>({});

  const setReopenAction = useCallback(() => {
    setIsFetching(true);

    fetchReopenAction(newComment)
      .then(() => {
        toastSuccess({ title: t('createSuccess') });
        setIsFetching(false);
        setIsRefresh({});
        updateFunction();
        onClose(false);
      })
      .catch((error: Error) => {
        if (error.data) {
          for (const [key, value] of Object.entries(error.data)) {
            setError(backendNamesToFormNamesMapping[key], {
              message: value.toString(),
            });
          }
        } else {
          toastError({ title: t('createError') });
        }

        if (isAbortError(error as unknown as Record<string, unknown>)) {
          return;
        }

        setIsFetching(false);
      });
  }, [newComment]);

  useEffect(() => {
    if (newComment.incidentId) {
      setReopenAction();
    }
  }, [newComment]);

  return {
    isFetching,
    setNewComment,
  };
};
