import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { ColumnSeries, XYChart } from '@amcharts/amcharts4/charts';
import { getIntervalToDuration } from '@soc/kit/date';

import {
  createRange,
  drawGird,
  getColumnSizePx,
  setColumnSize,
  setupSeries,
} from 'components/EpsWidget/helpers/applyToChartHelpers';
import { COLUMN_CONFIG } from 'components/EpsWidget/helpers/constants';
import { DurationFormat } from 'components/EpsWidget/helpers/enums';
import {
  AmchartEpsData,
  EpsAnalyticData,
  EpsAnalyticDataForAdd,
  EpsWidget,
  EventTarget,
  ExcessBuckets,
  RangeEvent,
  Timeline,
} from 'components/EpsWidget/helpers/types';

const generateAmchartEpsData = ({
  timeline: data = [],
  ...extData
}: EpsAnalyticData): AmchartEpsData[] => {
  if (!data.length) {
    return [];
  }

  const isValue = (value: number, contractedEps: number): boolean =>
    value > contractedEps && contractedEps !== 0;

  const mappedData = data.map(
    ({ item, timespan, value, ...rest }: Timeline): AmchartEpsData => ({
      date: item,
      value: isValue(value, extData.contracted_eps)
        ? extData.contracted_eps
        : value,
      excess: isValue(value, extData.contracted_eps)
        ? value - extData.contracted_eps
        : 0,
      timespan,
      ...rest,
    }),
  );

  const [first, ...restData] = mappedData;
  const firstWithExtData: AmchartEpsData = {
    ...first,
    extData: extData as EpsAnalyticDataForAdd,
  };

  return [firstWithExtData, ...restData];
};

const getDurationFormatted = (
  milliseconds: number | undefined,
): React.JSX.Element => {
  if (!milliseconds) {
    return <> </>;
  }

  const prevData = new Date(new Date().getTime() - milliseconds);
  const isInvalidDate = Number.isNaN(prevData.getTime());

  if (isInvalidDate) {
    return <> </>;
  }

  const duration = getIntervalToDuration(
    new Date(new Date().getTime() - milliseconds),
    new Date(),
  );

  if (milliseconds >= DurationFormat.Day) {
    return (
      <Translate value="duration.day" d={duration.days} h={duration.hours} />
    );
  }
  if (milliseconds >= DurationFormat.Hour) {
    return (
      <Translate
        value="duration.hour"
        h={duration.hours}
        m={duration.minutes}
      />
    );
  }
  if (milliseconds >= DurationFormat.Minute) {
    return (
      <Translate
        value="duration.minute"
        m={duration.minutes}
        s={duration.seconds}
      />
    );
  }
  if (milliseconds >= DurationFormat.Second) {
    return (
      <Translate
        value="duration.second"
        s={duration.seconds}
        // @ts-ignore
        ms={milliseconds - duration.seconds * 1000}
      />
    );
  }
  return <Translate value="duration.millisecond" ms={milliseconds} />;
};

export const getEpsWidget = (): EpsWidget => {
  return {
    dataGenerator: generateAmchartEpsData,
    config: COLUMN_CONFIG,
    generalBlock: {
      title: I18n.t('widgets.epsWidget.generalBlock.title'),
      getValueFromData: ([first]: AmchartEpsData[]): React.JSX.Element => {
        return getDurationFormatted(first?.extData?.excess_duration);
      },
    },
    applyToChart: (chart: XYChart): void => {
      const rangeOver = (event: RangeEvent): void => {
        const seriesVal = chart.series.getIndex(0)! as ColumnSeries;
        const seriesExcess = chart.series.getIndex(1)! as ColumnSeries;

        (event as RangeEvent & EventTarget).target.fillOpacity = 0.1;
        const { values } = (event as RangeEvent & EventTarget).target.dataItem;

        if (values.range) {
          (
            event as RangeEvent & EventTarget
          ).target.dataItem.contents.strokeOpacity = 0.9;
        }

        const buckets: number[] = values ? values.timeline_buckets : [];

        if (buckets.length) {
          const startDate = buckets.slice(-1)[0];
          const index = chart.data.findIndex(
            (item) => `${startDate}` === `${item.date}`,
          );

          for (let i = index; i < index + buckets.length; i++) {
            const valColumn = seriesVal.columns.getIndex(i);

            const excessColumn = seriesExcess.columns.getIndex(i);

            if (valColumn && excessColumn) {
              valColumn.setState('over');
              excessColumn.setState('over');
            }
          }
        }
      };

      const rangeOut = (event: RangeEvent): void => {
        const seriesVal = chart.series.getIndex(0)! as ColumnSeries;
        const seriesExcess = chart.series.getIndex(1)! as ColumnSeries;
        (event as RangeEvent & EventTarget).target.fillOpacity = 0;

        const { values } = (event as RangeEvent & EventTarget).target.dataItem;

        if (values.range) {
          (
            event as RangeEvent & EventTarget
          ).target.dataItem.contents.strokeOpacity = 0.5;
        }

        const buckets = values ? values.timeline_buckets : [];

        if (buckets.length > 0) {
          const startDate = buckets.slice(-1)[0];
          const index = chart.data.findIndex(
            (item) => `${startDate}` === `${item.date}`,
          );

          for (let i = index; i < index + buckets.length; i++) {
            const valColumn = seriesVal.columns.getIndex(i);

            const excessColumn = seriesExcess.columns.getIndex(i);

            if (valColumn && excessColumn) {
              valColumn.setState('default');
              excessColumn.setState('default');
            }
          }
        }
      };

      const valueAxis = chart.yAxes.getIndex(0)!;

      valueAxis.renderer.grid.template.disabled = true;
      valueAxis.renderer.labels.template.disabled = true;
      setupSeries(chart);
      setColumnSize(chart);

      chart.events.on('datavalidated', (ev) => {
        drawGird(chart, ev.target.data as AmchartEpsData[]);
      });
      chart.events.on('beforedatavalidated', (ev) => {
        const data = chart.data || [];
        const lineSeries = chart.series.getIndex(2) as ColumnSeries;
        const [graphColumnSizePx] = getColumnSizePx(
          ev.target.data as AmchartEpsData[],
        );
        setColumnSize(chart);
        lineSeries.axisRanges.clear();
        chart.xAxes.getIndex(0)?.axisRanges.clear();
        const pxOnDate = chart.pixelWidth / data.length;
        const dateOnPx =
          (data[data.length - 1].date - data[0].date) / chart.pixelWidth;
        const subValue = ((pxOnDate - graphColumnSizePx) * dateOnPx) / 2;

        if (chart.data.length && chart.data[0].extData) {
          (
            chart as XYChart & { data: EpsAnalyticData }
          ).data[0].extData.excess_buckets.map((elem: ExcessBuckets) => {
            const dateObj = new Date(elem.start + subValue);
            const endDateObj = new Date(elem.end - subValue);
            createRange(chart, dateObj, endDateObj, elem, rangeOver, rangeOut);
            return null;
          });
        }
      });
    },
  };
};
