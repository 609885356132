import { useCallback, useEffect, useState } from 'react';

import { abortApiFetch, isAbortError, useApiToastError } from '@soc/kit/api';
import { BaseSelectOption } from 'combinezone/core/Select/types';

import { fetchResolutions } from '../actions/fetchResolutions';
import { resolutionFetchAbortControllerKey } from '../constants';
import { convertResolutionsToOptions } from '../helpers';
import { UseResolutions } from '../types';

export const useResolutions: UseResolutions = ({ issueType }) => {
  const apiToastError = useApiToastError();
  const [data, setData] = useState<BaseSelectOption[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [inlineSearch, setInlineSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const getSystems = useCallback(() => {
    setIsFetching(true);

    fetchResolutions({ issueType, search: inlineSearch })
      .then((res) => {
        const convertedResultsData = convertResolutionsToOptions(res.results);

        setData(convertedResultsData);
        setIsFetching(false);
      })
      .catch((error: Error) => {
        apiToastError(error);
        setData([]);

        if (isAbortError(error as unknown as Record<string, unknown>)) {
          return;
        }
        setIsFetching(false);
      });
  }, [inlineSearch, issueType]);

  useEffect(() => {
    if (isOpen) {
      getSystems();
    }

    return () => {
      abortApiFetch(resolutionFetchAbortControllerKey);
      setData([]);
    };
  }, [inlineSearch, isOpen]);

  return {
    data,
    isFetching,
    setInlineSearch,
    setIsOpen,
  };
};
