import React, { useCallback, useEffect, useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import styled from 'styled-components';

import { Form } from '@bizone/ui-bundle/esm/Form';
import { Input } from '@bizone/ui-bundle/esm/Input';
import { Message } from '@bizone/ui-bundle/esm/Message';

import {
  Caption,
  Flex,
  PinInput,
  Text,
  Tooltip,
  Button,
  Heading,
  Spinner,
} from 'combinezone/core';

import { loginLogo } from 'branding';
import { Countdown } from 'components/Countdown';
import { socProperties } from 'utils/brending';

import { useOidcProviders } from './hooks/useOidcProviders';
import { ProviderButton } from './shared/components/ProviderButton';

import './Login.scss';

const FormItem = Form.Item;

const testId = 'SOC_PORTAL';

const ProviderContainer = styled(Flex)`
  height: 100%;
`;

const NameCompany = styled(Heading)`
  font-size: 48px;
  font-weight: 700;
`;

const NamePortal = styled(Heading)`
  font-size: 48px;
  font-weight: 400;
`;

const CenterLine = styled.div`
  background: ${({ theme }) => theme.components.card.colors.border.normal};
  height: 1px;
  width: 100%;
  margin-bottom: 24px;
`;

const Login = ({
  checkSmsCode,
  errors,
  hasMfa,
  isLoading,
  login,
  resetMfa,
  sendSmsCode,
  timeToGetNextMfaToken,
}) => {
  const [pin, setPin] = useState('');
  const [isValidPin, setIsValidPin] = useState(true);
  const [canSendSms, setCanSendSms] = useState(false);
  const { isFetchingProviders, providers } = useOidcProviders();

  const countDown = useCallback(
    ({ remainMin, remainSec }) => (
      <Caption
        testId={`${testId}_Refresh_Code_Countdown`}
      >{`${remainMin}:${remainSec.toString().padStart(2, '0')}`}</Caption>
    ),
    [],
  );

  const countDownOnFinish = useCallback(() => setCanSendSms(true), []);

  const onCompleteEnterPin = useCallback((pin) => {
    checkSmsCode(pin).then(({ error }) => {
      error && setIsValidPin(false);
    });
  }, []);

  const onChangePinInput = useCallback((pin) => {
    setPin(pin);
    setIsValidPin(true);
  }, []);

  const resetPinInput = useCallback(() => {
    setPin('');
    setIsValidPin(true);
    setCanSendSms(false);
  }, []);

  const onCancel = useCallback(() => {
    resetPinInput();
    resetMfa();
  }, [resetMfa]);

  const reSendPin = useCallback(() => {
    resetPinInput();
    setPin('');
    sendSmsCode();
  }, [sendSmsCode]);

  useEffect(() => {
    resetPinInput();
  }, [hasMfa]);

  const handleSubmit = async (values) => {
    login(values.username, values.password);
  };

  const handleValidate = (values) => {
    const errors = {};

    if (!values.username) {
      errors.username = I18n.t('login.usernameRequired');
    }

    if (!values.password) {
      errors.password = I18n.t('login.passwordRequired');
    }
    return errors;
  };

  return (
    <Flex
      style={{
        minWidth: 500,
        height: '100vh',
      }}
      alignItems="center"
      justify="center"
      direction="column"
      gap="40px"
    >
      <Flex alignItems="center">
        {socProperties.brandName !== 'TTC' ? (
          <>
            <img
              style={{ width: '130px' }}
              src={loginLogo.default}
              alt="logo"
            />
            <Flex direction="column" gap="24px">
              <NameCompany>BI.ZONE</NameCompany>
              <NamePortal>SOC Portal</NamePortal>
            </Flex>
          </>
        ) : (
          <Flex gap="24px" alignItems="center">
            <img
              style={{ width: '250px' }}
              src={loginLogo[socProperties.brandName]}
              alt="logo"
            />
            <NamePortal>SOC Portal</NamePortal>
          </Flex>
        )}
      </Flex>
      {hasMfa ? (
        <Flex alignItems="center" direction="column" gap="8px">
          <Flex alignItems="center" direction="column" gap="16px">
            <Text>
              <Translate value="login.pinSent" />
            </Text>
            <Tooltip
              content={errors.map((error) => (
                <div key={error}>{error}</div>
              ))}
              isOpen={!!errors.length && !isValidPin}
              inverted
            >
              <div>
                <PinInput
                  testId={`${testId}_MFA_PIN`}
                  autoFocus
                  length={6}
                  isInvalid={!isValidPin}
                  onChange={onChangePinInput}
                  value={pin}
                  onComplete={onCompleteEnterPin}
                />
              </div>
            </Tooltip>
            <Button testId={`${testId}_Cancel_Button`} onClick={onCancel}>
              <Translate value="login.cancel" />
            </Button>
            <Button
              variant="secondary"
              type="submit"
              isLoading={isLoading}
              disabled={!canSendSms}
              onClick={reSendPin}
            >
              <Translate value="login.pinResend" />
            </Button>
          </Flex>
          <Flex alignItems="center" direction="column" gap="16px">
            {!canSendSms && (
              <Caption>
                <Translate value="login.pinResendAccess" />
                <Countdown
                  startValueSec={timeToGetNextMfaToken}
                  onFinish={countDownOnFinish}
                >
                  {countDown}
                </Countdown>
              </Caption>
            )}
          </Flex>
        </Flex>
      ) : (
        <div className="form-wrapper">
          <Form
            onSubmit={handleSubmit}
            validate={handleValidate}
            cls="login-form"
            initialValues={{}}
          >
            {({ handleSubmit }) => (
              <React.Fragment>
                <Flex gap="8px" direction="column" alignItems="start">
                  <Text>Имя пользователя</Text>
                  <FormItem name="username">
                    <Input
                      placeholder={I18n.t('login.username')}
                      icon="profile"
                    />
                  </FormItem>
                </Flex>
                <Flex gap="8px" direction="column" alignItems="start">
                  <Text>Пароль</Text>
                  <FormItem name="password">
                    <Input
                      type="password"
                      placeholder={I18n.t('login.password')}
                      icon="lock-outline"
                    />
                  </FormItem>
                </Flex>
                <div className="submit-button-wrapper">
                  <Button
                    isFullWidth
                    testId={`${testId}-button-submit`}
                    variant="secondary"
                    type="submit"
                    isLoading={isLoading}
                    onClick={handleSubmit}
                  >
                    <Translate value="login.submit" />
                  </Button>
                </div>
                {errors.length ? (
                  <Message error icon="error">
                    {errors.map((error) => (
                      <div key={error}>{error}</div>
                    ))}
                  </Message>
                ) : null}
              </React.Fragment>
            )}
          </Form>
          {socProperties.brandName !== 'TTC' ? (
            <ProviderContainer
              justify="flex-start"
              alignItems="center"
              direction="column"
            >
              {isFetchingProviders ? (
                <Spinner />
              ) : (
                <>
                  <CenterLine />
                  <Text>Войти через</Text>
                  <Flex
                    gap="16px"
                    direction="column"
                    fillWidth
                    style={{ marginTop: '16px' }}
                  >
                    {providers?.map(
                      ({ client_id, configuration_url, name }, index) => (
                        <ProviderButton
                          key={`${client_id}-${index}`}
                          testId={`${testId}-${index}`}
                          client_id={client_id}
                          name={name}
                          configuration_url={configuration_url}
                          isLoading={isLoading}
                        />
                      ),
                    )}
                  </Flex>
                </>
              )}
            </ProviderContainer>
          ) : null}
        </div>
      )}
    </Flex>
  );
};

export default Login;
