import React, { FC } from 'react';

import { Flex, Spinner } from 'combinezone/core';

import ActionLink from './components/ActionLink';
import CloseActionModal from './components/CloseActionModal';
import ConfirmActionModal from './components/ConfirmActionModal';
import ReopenActionModal from './components/ReopenActionModal';
import { StatusActions as StatusActionsEnum } from './enums';
import { useStatusActions } from './hooks/useStatusActions';
import { StatusActionsProps } from './types';

const StatusActions: FC<StatusActionsProps> = ({
  incidentId,
  issueType,
  testId,
  updateFunction,
}) => {
  const { data, isFetching, setIsRefresh } = useStatusActions({ incidentId });
  const statusActionsTestId = `${testId}-status-actions`;

  return (
    <Flex fillWidth testId={`${statusActionsTestId}-wrapper`} gap="12px">
      {isFetching && <Spinner size="sm" />}
      {!data.length && !isFetching && '—'}

      <ActionLink
        status={StatusActionsEnum.Confirm}
        data={data}
        incidentId={incidentId}
        isFetching={isFetching}
        modal={ConfirmActionModal}
        setIsRefresh={setIsRefresh}
        testId={statusActionsTestId}
        issueType={issueType}
        updateFunction={updateFunction}
      />

      <ActionLink
        status={StatusActionsEnum.Reopen}
        data={data}
        incidentId={incidentId}
        isFetching={isFetching}
        modal={ReopenActionModal}
        setIsRefresh={setIsRefresh}
        testId={statusActionsTestId}
        issueType={issueType}
        updateFunction={updateFunction}
      />

      <ActionLink
        status={StatusActionsEnum.Close}
        data={data}
        incidentId={incidentId}
        isFetching={isFetching}
        modal={CloseActionModal}
        setIsRefresh={setIsRefresh}
        testId={statusActionsTestId}
        issueType={issueType}
        updateFunction={updateFunction}
      />
    </Flex>
  );
};
export default StatusActions;

StatusActions.displayName = 'StatusActions';
