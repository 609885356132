import { createAction } from 'redux-api-middleware';

import { getFilterParams, withHeaders } from '..';
import { FILTERS_ENDPOINT } from '../../../services/api';

import * as types from './actionTypes';

export const fetchFilters = () =>
  createAction({
    endpoint: FILTERS_ENDPOINT,
    method: 'GET',
    headers: withHeaders({ 'Content-Type': 'application/json' }),
    types: [
      types.FETCH_FILTERS_REQUEST,
      types.FETCH_FILTERS_SUCCESS,
      types.FETCH_FILTERS_FAIL,
    ],
  });

export const searchChange = (entity, search, callbacks) => {
  return (dispatch) => {
    dispatch({
      type: types.SEARCH_CHANGE,
      entity,
      payload: search,
    });

    updateData(callbacks, dispatch);
  };
};

export const setFilter = (entity, key, items, callbacks, filterTagText) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_FILTER,
      entity,
      payload: { key, items, filterTagText },
    });
    updateData(callbacks, dispatch);
  };
};

export const resetFilters = (entity, callbacks) => {
  return (dispatch) => {
    dispatch({
      type: types.RESET_FILTERS,
      entity,
    });

    updateData(callbacks, dispatch);
  };
};

export const showFilters = (entity) => ({
  type: types.SHOW_FILTERS,
  entity,
});

export const localSaveFilters =
  ({ entity, history, pathname }) =>
  (dispatch, getState) => {
    const savedFilters = JSON.parse(localStorage.getItem('socFilters'));

    const newFilters = {
      ...savedFilters,
      [entity]: `${getFilterParams({ state: getState(), entity })}`,
    };
    localStorage.setItem('socFilters', JSON.stringify(newFilters));

    history?.push({
      pathname,
      search: newFilters[entity],
    });
  };

export const setSort = (entity, ordering, callbacks) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_SORT,
      entity,
      meta: { ordering },
    });

    updateData(callbacks, dispatch);
  };
};

function updateData(callbacks, dispatch) {
  if (callbacks) {
    if (Array.isArray(callbacks)) {
      callbacks.forEach((cb) => dispatch(cb()));
    } else {
      dispatch(callbacks());
    }
  }
}
