import { createAction } from 'redux-api-middleware';

import * as types from './actionTypes';

export const fetchTemplatesLinks = (id) => (dispatch) => {
  const action = createAction({
    endpoint: `/api/v0/alerts/${id}/available_links_templates/`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [
      types.FETCH_TEMPLATES_LINKS_REQUEST,
      types.FETCH_TEMPLATES_LINKS_SUCCESS,
      types.FETCH_TEMPLATES_LINKS_FAILURE,
    ],
    isAbortOldRequests: true,
  });

  return dispatch(action);
};
