import { createAction } from 'redux-api-middleware';

import { articlesNextPage, withHeaders, getFilterParams } from '..';
import { ARTICLES_ENDPOINT, ARTICLES_ENTITY } from '../../../services/api';

import * as types from './actionTypes';

export const firstFetch = () => (dispatch, getState) => {
  const timestamp = Date.now();
  const params = getFilterParams({
    state: getState(),
    entity: ARTICLES_ENTITY,
  });

  const action = createAction({
    endpoint: `${ARTICLES_ENDPOINT}${params}`,
    method: 'GET',
    headers: withHeaders({ 'Content-Type': 'application/json' }),
    types: [
      {
        type: types.FIRST_FETCH_REQUEST,
        meta: { timestamp },
      },
      {
        type: types.FIRST_FETCH_SUCCESS,
        meta: { timestamp },
      },
      {
        type: types.FIRST_FETCH_FAIL,
        meta: { timestamp },
      },
    ],
  });

  return dispatch(action);
};

export const fetchMore = () => (dispatch, getState) => {
  const state = getState();
  const page = articlesNextPage(state);
  const { lastFetchTime } = state.articles;
  const params = getFilterParams({
    state: getState(),
    entity: ARTICLES_ENTITY,
  });

  const action = createAction({
    endpoint: `${ARTICLES_ENDPOINT}${params}&page=${page}`,
    method: 'GET',
    headers: withHeaders({ 'Content-Type': 'application/json' }),
    types: [
      types.FETCH_MORE_REQUEST,
      {
        type: types.FETCH_MORE_SUCCESS,
        meta: { timestamp: lastFetchTime },
      },
      {
        type: types.FETCH_MORE_FAIL,
        meta: { showNotification: false },
      },
    ],
  });

  return dispatch(action);
};
