import { BaseSelectOption } from 'combinezone/core/Select/types';

import { ResolutionItem } from './types';

export const convertResolutionsToOptions = (
  data: ResolutionItem[],
): BaseSelectOption[] => {
  if (!data.length) {
    return [];
  }

  return data.map(({ id, title }) => ({
    value: id,
    content: title,
    testId: `${title}`,
  }));
};
