import { createAction } from 'redux-api-middleware';

import {
  incidentListNextPage,
  incidentListSelected,
  withHeaders,
  getFilterParams,
} from '..';
import {
  KEY_FIELD,
  INCIDENTS_ENDPOINT,
  INCIDENTS_ENTITY,
} from '../../../services/api';

import * as types from './actionTypes';

const replaceParams = (params) => {
  const replacedParams = params
    .replace(
      `customer_assignee__username__in=absent`,
      `customer_assignee__isnull=True`,
    )
    .replace(
      `customer_assignee__username__in=any`,
      `customer_assignee__isnull=False`,
    );

  return replacedParams;
};

export const firstFetch = () => (dispatch, getState) => {
  const timestamp = Date.now();
  const params = getFilterParams({
    state: getState(),
    entity: INCIDENTS_ENTITY,
  });

  const replacedParams = replaceParams(params);

  return dispatch(
    createAction({
      endpoint: `${INCIDENTS_ENDPOINT}${replacedParams}`,
      method: 'GET',
      headers: withHeaders({ 'Content-Type': 'application/json' }),
      types: [
        {
          type: types.FIRST_FETCH_REQUEST,
          meta: { timestamp },
        },
        {
          type: types.FIRST_FETCH_SUCCESS,
          meta: { timestamp },
        },
        {
          type: types.FIRST_FETCH_FAIL,
          meta: { timestamp },
        },
      ],
    }),
  );
};

export const fetchMore = () => (dispatch, getState) => {
  const page = incidentListNextPage(getState());
  const params = getFilterParams({
    state: getState(),
    entity: INCIDENTS_ENTITY,
  });

  const replacedParams = replaceParams(params);

  return dispatch(
    createAction({
      endpoint: `${INCIDENTS_ENDPOINT}${replacedParams}&page=${page}`,
      method: 'GET',
      headers: withHeaders({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_MORE_REQUEST,
        types.FETCH_MORE_SUCCESS,
        {
          type: types.FETCH_MORE_FAIL,
          meta: { showNotification: false },
        },
      ],
    }),
  );
};

export const select = (incident = {}) => ({
  type: types.SELECT_INC,
  meta: { incident },
});

export const sendFeedback = (body) => (dispatch, getState) => {
  const { [KEY_FIELD]: incNumber } = incidentListSelected(getState());

  return dispatch(
    createAction({
      endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/add_rating/`,
      method: 'POST',
      body: JSON.stringify(body),
      headers: withHeaders({ 'Content-Type': 'application/json' }),
      types: [
        {
          type: types.ADD_FEEDBACK_REQUEST,
          meta: { incNumber, body },
        },
        {
          type: types.ADD_FEEDBACK_SUCCESS,
          meta: { incNumber, body },
        },
        {
          type: types.ADD_FEEDBACK_FAILURE,
          meta: { incNumber, body, showNotification: false },
        },
      ],
    }),
  );
};
