import { api, SOC_URL } from '@soc/kit/api';

import { CloseActionFetch } from '../types';

export const fetchCloseAction: CloseActionFetch = ({
  assignToMyself,
  comment,
  incidentId,
  resolution,
}) => {
  const body = JSON.stringify({
    change_customer_assignee: assignToMyself,
    resolution_name: resolution,
    comment,
  });

  return api<unknown>(`${SOC_URL}incs/${incidentId}/close/`, {
    method: 'post',
    body,
  });
};
