import { RSAA } from 'redux-api-middleware';

import { withHeaders } from '..';
import {
  encodeProtectedSystemsFilter,
  SYSTEMS_ENDPOINT,
  fetchAllPages,
} from '../../../services/api';

import * as types from './actionTypes';

export const fetchProtectedSystems = ({ filters = {}, sort = {} } = {}) => ({
  [RSAA]: {
    endpoint: `${SYSTEMS_ENDPOINT}?${encodeProtectedSystemsFilter(
      filters,
      sort,
    )}`,
    method: 'GET',
    headers: withHeaders({}),
    types: [
      types.FETCH_PROTECTED_SYSTEMS_REQUEST,
      types.FETCH_PROTECTED_SYSTEMS_SUCCESS,
      types.FETCH_PROTECTED_SYSTEMS_FAIL,
    ],
    fetch: fetchAllPages,
  },
});
