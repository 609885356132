import * as templatesType from 'components/AlertList/actions/fetchTemplatesLinks/actionTypes';
import { ASSIGN_TO_INCIDENT_SUCCESS } from 'store/reducers/common/actions/assign/actionTypes';

import { RATING_COMMENT, RATING_STARS } from '../../../services/api';

import * as types from './actionTypes';

const initialState = {
  incident: {},
  comments: {
    fetching: false,
    data: [],
  },
  alerts: {
    fetching: false,
    selected: {},
    data: [],
    search: '',
    totalCount: 0,
    hasMore: false,
    nextPage: 2,
    lastFetchTime: null,
  },
  addComment: {
    errors: {},
    submitting: false,
  },
  fetchedAlert: null,
  fetchedAlertGroupedData: undefined,
  fetchedAlertTree: {
    data: null,
    fetching: false,
    error: null,
  },
  fetchedAlertSymptomatic: {
    data: null,
    fetching: false,
    error: null,
  },
  gossopkaLoading: false,
  error: null,
  fetching: false,
  closeIncident: {
    submitting: false,
  },
  cmdbAssets: {
    data: [],
    fetching: false,
    fetchingMore: false,
    next: null,
  },
  isFetchingTemplatesLinks: false,
  templatesLinks: {},
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_INC_CARD_REQUEST:
      return { ...state, fetching: true, error: false, gossopkaLoading: false };

    case types.FETCH_INC_CARD_SUCCESS:
      return { ...state, incident: action.payload };

    case types.FETCH_INC_CARD_FAIL:
      return { ...state, fetching: false, error: true };

    case types.UPDATE_INC_CARD_REQUEST:
      return { ...state, updating: true, error: false };

    case types.UPDATE_INC_CARD_SUCCESS:
      return { ...state, incident: action.payload };

    case types.UPDATE_INC_CARD_FAIL:
      return { ...state, updating: false, error: true };

    case types.FETCH_COMMENTS_REQUEST:
      return {
        ...state,
        comments: {
          data: [],
          fetching: true,
        },
      };

    case types.FETCH_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: {
          data: action.payload,
          fetching: false,
        },
      };

    case types.FETCH_COMMENTS_FAILURE:
      return {
        ...state,
        comments: {
          ...state.comments,
          fetching: false,
        },
      };

    case types.FETCH_INC_CARD_ALERTS_REQUEST:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          data: [],
          fetching: true,
        },
      };

    case types.FETCH_INC_CARD_ALERTS_SUCCESS:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          data: action.payload,
          fetching: false,
        },
      };

    case types.FETCH_INC_CARD_ALERTS_FAIL:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          fetching: false,
        },
      };

    case types.FETCH_INC_CARD_SELECTED_ALERT_REQUEST:
      return {
        ...state,
        fetchedAlert: null,
      };

    case types.FETCH_INC_CARD_SELECTED_ALERT_SUCCESS:
      return {
        ...state,
        fetchedAlert: action.payload,
      };

    case types.FETCH_INC_CARD_SELECTED_ALERT_FAIL:
      return {
        ...state,
        fetchedAlert: {},
      };
    case types.FETCH_INC_CARD_SELECTED_ALERT_GROUPED_DATA_REQUEST:
      return {
        ...state,
        fetchedAlertGroupedData: undefined,
      };

    case types.FETCH_INC_CARD_SELECTED_ALERT_GROUPED_DATA_SUCCESS:
      return {
        ...state,
        fetchedAlertGroupedData: action.payload.results,
      };

    case types.FETCH_INC_CARD_SELECTED_ALERT_GROUPED_DATA_FAIL:
      return {
        ...state,
        fetchedAlertGroupedData: [],
      };

    case types.FETCH_INC_CARD_SELECTED_ALERT_TREE_REQUEST:
      return {
        ...state,
        fetchedAlertTree: {
          fetching: true,
          error: null,
          data: null,
        },
      };

    case types.FETCH_INC_CARD_SELECTED_ALERT_TREE_SUCCESS:
      return {
        ...state,
        fetchedAlertTree: {
          fetching: false,
          error: null,
          data: {
            ...action.payload,
            relation: Array.isArray(action.payload.relation)
              ? action.payload.relation?.pop()
              : action.payload.relation,
          },
        },
      };

    case types.FETCH_INC_CARD_SELECTED_ALERT_TREE_FAIL:
      return {
        ...state,
        fetchedAlertTree: {
          fetching: false,
          error: Array.isArray(action.payload.response.non_field_errors)
            ? action.payload.response.non_field_errors[0]
            : null,
        },
      };

    case types.FETCH_INC_CARD_SELECTED_ALERT_SYMPTOMATIC_REQUEST:
      return {
        ...state,
        fetchedAlertSymptomatic: {
          fetching: true,
          error: null,
          data: null,
        },
      };

    case types.FETCH_INC_CARD_SELECTED_ALERT_SYMPTOMATIC_SUCCESS:
      return {
        ...state,
        fetchedAlertSymptomatic: {
          fetching: false,
          data: action.payload,
        },
      };

    case types.FETCH_INC_CARD_SELECTED_ALERT_SYMPTOMATIC_FAIL:
      return {
        ...state,
        fetchedAlertSymptomatic: {
          fetching: false,
          data: null,
          error: action.payload.response,
        },
      };

    case types.INC_CARD_SELECT_ALERT:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          selected: action.payload,
        },
      };

    case types.INC_CARD_SET_ALERT_SEARCH:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          search: action.payload,
        },
      };

    case types.ADD_FEEDBACK_SUCCESS:
      return {
        ...state,
        incident: {
          ...state.incident,
          [RATING_STARS]: action.meta.body.rating,
          [RATING_COMMENT]: action.meta.body.comment,
        },
      };

    case types.CLOSE_INC_CARD:
      return { ...initialState };

    case types.ADD_COMMENT_REQUEST: {
      return {
        ...state,
        addComment: {
          ...state.addComment,
          errors: {},
          submitting: true,
        },
      };
    }

    case types.ADD_COMMENT_FAIL: {
      if (state.incident.key !== action.meta.incNumber) {
        return state;
      }
      return {
        ...state,
        addComment: {
          ...state.addComment,
          errors: action.payload,
          submitting: false,
        },
      };
    }

    case types.ADD_COMMENT_SUCCESS: {
      if (state.incident.key !== action.meta.incNumber) {
        return state;
      }

      const incident = action.meta.isCompleted
        ? {
            ...state.incident,
            status: 'Customer completed',
          }
        : state.incident.status === 'Waiting for customer'
        ? {
            ...state.incident,
            status: 'Waiting for support',
          }
        : state.incident;

      return {
        ...state,
        incident,
        addComment: {
          ...state.addComment,
          submitting: false,
        },
        comments: {
          ...state.comments,
          data: [...state.comments.data, action.payload],
        },
      };
    }

    case types.ADD_COMMENT_MODAL_SUCCESS: {
      return {
        ...state,
        addComment: {
          ...initialState.addComment,
        },
      };
    }

    case types.SEND_GOSSOPKA_REQUEST: {
      return {
        ...state,
        gossopkaLoading: true,
      };
    }

    case types.SEND_GOSSOPKA_FAILURE: {
      return {
        ...state,
        gossopkaLoading: false,
      };
    }

    case types.SEND_GOSSOPKA_SUCCESS: {
      return {
        ...state,
        gossopkaLoading: false,
      };
    }

    case types.FETCH_INC_CARD_ALERTS_FIRST_PAGE_REQUEST: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          totalCount: 0,
          fetching: true,
        },
      };
    }

    case types.FETCH_INC_CARD_ALERTS_FIRST_PAGE_SUCCESS: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          totalCount: action.payload.count,
          data: action.payload.results,
          fetching: false,
          hasMore: !!action.payload.next,
        },
      };
    }

    case types.FETCH_INC_CARD_ALERTS_MORE_PAGE_REQUEST: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          fetching: true,
        },
      };
    }

    case types.FETCH_INC_CARD_ALERTS_MORE_PAGE_SUCCESS: {
      if (state.alerts.lastFetchTime > action.meta.timestamp) {
        return state;
      }

      return {
        ...state,
        alerts: {
          ...state.alerts,
          fetching: false,
          data: [...state.alerts.data, ...action.payload.results],
          nextPage: state.alerts.nextPage + 1,
          hasMore: !!action.payload.next,
        },
      };
    }

    case types.FETCH_INC_CARD_CMDB_ASSETS_REQUEST:
      return {
        ...state,
        cmdbAssets: {
          ...state.cmdbAssets,
          fetching: true,
        },
      };

    case types.FETCH_INC_CARD_CMDB_ASSETS_FAIL:
      return {
        ...state,
        cmdbAssets: {
          ...state.cmdbAssets,
          fetching: false,
        },
      };

    case types.FETCH_INC_CARD_CMDB_ASSETS_SUCCESS:
      return {
        ...state,
        cmdbAssets: {
          ...state.cmdbAssets,
          next: action.payload.next,
          data: action.payload.results,
          fetching: false,
        },
      };

    case types.FETCH_INC_CARD_MORE_CMDB_ASSETS_REQUEST:
      return {
        ...state,
        cmdbAssets: {
          ...state.cmdbAssets,
          fetchingMore: true,
        },
      };

    case types.FETCH_INC_CARD_MORE_CMDB_ASSETS_FAIL:
      return {
        ...state,
        cmdbAssets: {
          ...state.cmdbAssets,
          fetchingMore: false,
        },
      };

    case types.FETCH_INC_CARD_MORE_CMDB_ASSETS_SUCCESS:
      return {
        ...state,
        cmdbAssets: {
          ...state.cmdbAssets,
          next: action.payload.next,
          data: [...state.cmdbAssets.data, ...action.payload.results],
          fetchingMore: false,
        },
      };

    case types.FETCH_INC_CARD_ALERTS_FIRST_PAGE_FAIL:
    case types.FETCH_INC_CARD_ALERTS_MORE_PAGE_FAIL: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          fetching: false,
        },
      };
    }

    case ASSIGN_TO_INCIDENT_SUCCESS: {
      return {
        ...state,
        incident: action.payload,
      };
    }

    case templatesType.FETCH_TEMPLATES_LINKS_REQUEST: {
      return {
        ...state,
        isFetchingTemplatesLinks: true,
      };
    }

    case templatesType.FETCH_TEMPLATES_LINKS_FAILURE: {
      return {
        ...state,
        isFetchingTemplatesLinks: false,
        templatesLinks: {},
      };
    }

    case templatesType.FETCH_TEMPLATES_LINKS_SUCCESS: {
      return {
        ...state,
        isFetchingTemplatesLinks: false,
        templatesLinks: action.payload,
      };
    }

    default:
      return state;
  }
}

export function incident(state) {
  return state.incident;
}

export function fetchError(state) {
  return state.error;
}

export function addCommentSubmitting(state) {
  return state.addComment.submitting;
}

export function addCommentErrors(state) {
  return state.addComment.errors;
}

export function gossopkaLoading(state) {
  return state.gossopkaLoading;
}

export function commentsData(state) {
  return state.comments.data;
}

export function commentsFetching(state) {
  return state.comments.fetching;
}

export function fetchedAlert(state) {
  return state.fetchedAlert;
}

export function fetchedAlertGroupedData(state) {
  return state.fetchedAlertGroupedData;
}

export function fetchedAlertTree(state) {
  return state.fetchedAlertTree;
}

export function fetchedAlertSymptomatic(state) {
  return state.fetchedAlertSymptomatic;
}

export function selectedAlert(state) {
  return state.alerts.selected;
}

export function alerts(state) {
  return state.alerts.data;
}

export function alertsFetching(state) {
  return state.alerts.fetching;
}

export function alertsSearch(state) {
  return state.alerts.search;
}

export function getAlertsTotalCount(state) {
  return state.alerts.totalCount;
}

export function getNextPage(state) {
  return state.alerts.nextPage;
}

export function hasMore(state) {
  return state.alerts.hasMore;
}

export function cmdbAssets(state) {
  return state.cmdbAssets.data;
}

export function cmdbAssetsFetching(state) {
  return state.cmdbAssets.fetching;
}

export function cmdbAssetsIsFetchingMore(state) {
  return state.cmdbAssets?.fetchingMore;
}

export function cmdbAssetsNext(state) {
  return state.cmdbAssets?.next;
}

export function cmdbAssetsHasMore(state) {
  return !!state.cmdbAssets?.next;
}

export const alertPanelData = ({ incCard }) => incCard.fetchedAlert;
export const alertPanelGroupedEvents = ({ incCard }) =>
  incCard.fetchedAlertGroupedData;
export const alertPanelIsLoading = ({ incCard }) => false;
export const alertPanelSymptomatic = ({ incCard }) =>
  incCard.fetchedAlertSymptomatic.data;
export const alertPanelSymptomaticError = ({ incCard }) =>
  incCard.fetchedAlertSymptomatic.error;
export const alertPanelSymptomaticIsLoading = ({ incCard }) =>
  incCard.fetchedAlertSymptomatic.fetching;
export const alertPanelTree = ({ incCard }) => incCard.fetchedAlertTree.data;
export const alertPanelTreeError = ({ incCard }) =>
  incCard.fetchedAlertTree.error;
export const alertPanelTreeIsLoading = ({ incCard }) =>
  incCard.fetchedAlertTree.fetching;

export const isFetchingTemplatesLinks = ({ incCard }) =>
  incCard.isFetchingTemplatesLinks;

export const templatesLinks = ({ incCard }) => incCard.templatesLinks;
