import { createAction } from 'redux-api-middleware';

import { ALERTS_ENDPOINT } from 'services/api';

import * as types from './actionTypes';

export const goToLink = (id, template) => (dispatch) => {
  const action = createAction({
    endpoint: `${ALERTS_ENDPOINT}${id}/link/?template=${template}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [
      types.FETCH_LINK_REQUEST,
      types.FETCH_LINK_SUCCESS,
      types.FETCH_LINK_FAILURE,
    ],
  });

  return dispatch(action);
};
