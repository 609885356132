import React, { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { I18n } from 'react-redux-i18n';

import { getMomentObject } from '@common/soc-react-kit/src/utils/momentHelpers';
import { Dropdown, RangeDatepicker } from 'combinezone/core';

import Tag from '../Tag';

import './RangePicker.scss';
import { RangeDatepickerPresetsTypes, RangePickerProps } from '../../../types';

import { getEndOfMonth, getStartOfMonth, getSubDays } from '@soc/kit/date';

const RangePicker: FC<RangePickerProps> = ({
  filter,
  locale,
  setFilter,
  settings: { label },
  value = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [start, end] = value;
  const pickerValue: [Date | undefined, Date | undefined] = value.length
    ? [start?.toDate(), end?.toDate()]
    : [new Date(), new Date()];

  const onChange = useCallback(
    (values) => {
      const [start, end] = values;
      setFilter(filter, [
        getMomentObject(start).startOf('day'),
        getMomentObject(end).endOf('day'),
      ]);
      setIsOpen(false);
    },
    [setFilter],
  );

  useEffect(() => {
    !value.length && setIsOpen(true);
  }, []);

  const rangeDatepickerPresets: RangeDatepickerPresetsTypes = useMemo(
    () => [
      {
        title: I18n.t('filterPanel.rangePicker.last7days'),
        dateValue: [getSubDays(new Date(), 6), new Date()],
      },
      {
        title: I18n.t('filterPanel.rangePicker.last30days'),
        dateValue: [getSubDays(new Date(), 29), new Date()],
      },
      {
        title: I18n.t('filterPanel.rangePicker.thisMonth'),
        dateValue: [getStartOfMonth(new Date()), getEndOfMonth(new Date())],
      },
      {
        title: I18n.t('filterPanel.rangePicker.lastMonth'),
        dateValue: [
          getStartOfMonth(getSubDays(new Date(), 29)),
          getEndOfMonth(getSubDays(new Date(), 29)),
        ],
      },
    ],
    [locale],
  );

  const format = useMemo(
    () => I18n.t('filterPanel.rangePicker.format'),
    [locale],
  );

  const tagText = useMemo(
    () =>
      `${
        getMomentObject(start).locale(locale)?.format(format) ??
        I18n.t('filterPanel.rangePicker.chooseStart')
      }` +
      ` — ` +
      `${
        getMomentObject(end).locale(locale)?.format(format) ??
        I18n.t('filterPanel.rangePicker.chooseEnd')
      }`,
    [locale, start, end],
  );

  return (
    <div className="Filter-RangePicker">
      <Dropdown
        position="bottom-left"
        content={({ onClose }) => (
          <RangeDatepicker
            testId="Filter_RangeDatepicker"
            className="RangeDatepicker-FadeInput"
            presets={rangeDatepickerPresets}
            inline
            value={pickerValue}
            size="medium"
            dateFormat={format.toLowerCase()}
            onChange={(v) => {
              onChange(v);
              onClose();
            }}
          />
        )}
      >
        <Tag
          filter={filter}
          isActive={isOpen}
          setFilter={setFilter}
          onClick={() => setIsOpen((prev) => !prev)}
          label={label}
          text={tagText}
        />
      </Dropdown>
    </div>
  );
};

export default RangePicker;
