import { api } from '@soc/kit/api';
import { SOC_URL } from '@soc/kit/api/constants';

import { IssueTypesFetchAbortControllerKey, issueTypeFetchLimit } from '../constants';
import { IssueTypesFetch, IssueTypesResults } from '../types';

export const fetchIssueTypes: IssueTypesFetch = ({
  search,
}) => {
  const trimmedSearch = search.trim();
  const apiSearch = trimmedSearch ? `&q=title~"${trimmedSearch}" or id ~ "${trimmedSearch}"` : '';

  return api<IssueTypesResults>(
    `${SOC_URL}issues/issue_types/?limit=${issueTypeFetchLimit}${apiSearch}`,
    {
      method: 'get',
      abortControllerKey: IssueTypesFetchAbortControllerKey,
      isMainInstallation: true,
    },
  );
};
