import { api, SOC_URL } from '@soc/kit/api';

import { ReopenActionFetch } from '../types';

export const fetchReopenAction: ReopenActionFetch = ({
  assignToMyself,
  comment,
  incidentId,
}) => {
  const body = JSON.stringify({
    change_customer_assignee: assignToMyself,
    comment,
  });

  return api<unknown>(`${SOC_URL}incs/${incidentId}/reopen/`, {
    method: 'post',
    body,
  });
};
