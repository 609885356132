import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { Layout } from '@common/soc-react-kit';
import CmdbAsset from '@soc/assets/src';
import IssuePage from '@soc/issues/src';
import LogSources from '@soc/log-sources/src';
import { Dropdown, Menu } from 'antd';
import { Text, Badge } from 'combinezone/core';
import {
  Dashboard as DashboardIcon,
  FileReport,
  FileTxt,
  Incident as IncidentIcon,
  Profile,
  Source,
  System,
  Sensors,
  Case,
} from 'combinezone/icons';
import * as PropTypes from 'prop-types';

import { headerLogo } from 'branding';
import ThemeSwitcher from 'components/ThemeSwitcher';
import TranslateSwitcher from 'components/TranslateSwitcher';
import {
  getIsMounted,
  getUsername,
  currentLocale,
  getUser,
} from 'store/reducers';
import { logout } from 'store/reducers/auth/actions';
import { fetchFilters } from 'store/reducers/filters/actions';
import {socProperties, useTheme} from 'utils/brending';

import Article from '../../containers/ArticlePageContainer';
import ChangePassword from '../../containers/ChangePasswordContainer';
import Dashboard from '../../containers/DashboardContainer';
import DownloadNotification from '../../containers/DownloadNotificationContainer';
import IncidentList from '../../containers/IncidentListContainer';
import Incident from '../../containers/IncidentPageContainer';
import Instruction from '../../containers/InstructionPageContainer';
import ProtectedSystemCard from '../../containers/ProtectedSystemCardContainer';
import ProtectedSystems from '../../containers/ProtectedSystemsContainer';
import ResponseInstruction from '../../containers/ResponseInstructionsPageContainer';
import RulePage from '../../containers/RulePageContainer';
import CmdbAssetPage from '../CmdbAssetPage';
import CyberPolygonEvent from '../CyberPolygonEvent';
import CyberPolygonRule from '../CyberPolygonRule';
import Docs from '../Documentation';
import { FilePreview } from '../FilePreview';
import Reports from '../Reports';

import { createProtectedRoute } from './ProtectedRoute';

import './Main.scss';
import { Chat } from 'components/Chat';

const BadgeBeta = styled(Badge)`
  --_badge-color-text: hsl(230deg, 20%, 90%);
  padding: 4px;
`;

DashboardIcon.propTypes = { color: PropTypes.string };

export default function MainComponent() {
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedKeys = [location.pathname.match(/[^\/]+/)?.[0] ?? ''];
  const locale = useSelector(currentLocale);
  const user = useSelector(getUser);
  const username = useSelector(getUsername);
  const isMounted = useSelector(getIsMounted);
  const theme = useTheme();

  const isCyberPolygonLocation = location.pathname.includes('cyberpolygon');
  const isCyberPolygonAccount = username === 'cyberpolygon';
  const startCyberPolygon = isCyberPolygonLocation || isCyberPolygonAccount;
  const defaultRoute = startCyberPolygon ? '/cyberpolygon/event' : '/dashboard';
  const [, setValueForForceUpdate] = useState(0);

  const ProtectedRoute = useMemo(
    () =>
      createProtectedRoute({
        checkPermission: () => !isCyberPolygonAccount,
        redirectTo: defaultRoute,
      }),
    [defaultRoute, isCyberPolygonAccount, locale],
  );

  const handleLogout = useCallback(() => {
    return dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (!startCyberPolygon) {
      dispatch(fetchFilters());
    }
  }, [dispatch, startCyberPolygon]);

  useEffect(() => {
    const forceUpdate = () => setValueForForceUpdate((value) => value + 1);
    document.addEventListener('fullscreenchange', forceUpdate);

    return () => {
      document.removeEventListener('fullscreenchange', forceUpdate);
    };
  }, []);

  return (
    <div style={{ height: '100vh', overflow: 'hidden' }}>
      {!isMounted && (
        <div className="login-header">
          <Link className="main-logo" to={defaultRoute}>
            <img
              src={headerLogo[socProperties.brandName] || headerLogo.default}
              alt="logo"
            />
            <span>{socProperties.portalName}</span>
          </Link>
          <Menu
            className="Main-Menu"
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['dashboard']}
            selectedKeys={selectedKeys}
            forceSubMenuRender
            disabledOverflow
          >
            <Menu.Item key="dashboard">
              <Link to="/dashboard">
                <span className="ItemWithIcon">
                  <DashboardIcon color="currentColor" />
                  <Translate value="main.dashboard" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="incidents">
              <Link to="/incidents">
                <span className="ItemWithIcon">
                  <IncidentIcon color="currentColor" />
                  <Translate value="main.incidents" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="issues">
              <Link to="/issues/issues/task">
                <span className="ItemWithIcon">
                  <Case color="currentColor" />
                  <Translate value="main.issues" />
                  <BadgeBeta accent="warning">Beta</BadgeBeta>
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="protected-systems">
              <Link to="/protected-systems">
                <span className="ItemWithIcon">
                  <System color="currentColor" />
                  <Translate value="main.protectedSystems" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="log-sources">
              <Link to="/log-sources/total">
                <span className="ItemWithIcon">
                  <Source color="currentColor" />
                  <Translate value="main.logSources" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="reports">
              <Link to="/reports">
                <span className="ItemWithIcon">
                  <FileReport color="currentColor" />
                  <Translate value="main.reports" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="docs">
              <Link to="/docs">
                <span className="ItemWithIcon">
                  <FileTxt color="currentColor" />
                  <Translate value="main.docs" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="cmdb">
              <Link to="/cmdb/assets">
                <span className="ItemWithIcon">
                  <Sensors color="currentColor" />
                  <Translate value="main.assets" />
                  <BadgeBeta accent="warning">Beta</BadgeBeta>
                </span>
              </Link>
            </Menu.Item>
          </Menu>
          <Menu
            className="Sub-Menu"
            selectable={false}
            theme="dark"
            mode="horizontal"
            disabledOverflow
          >
            <Menu.Item key="translate">
              <TranslateSwitcher />
            </Menu.Item>
            <Menu.Item key="divider-one">
              <div className="Divider" />
            </Menu.Item>
            <Menu.Item key="theme">
              <ThemeSwitcher />
            </Menu.Item>
            <Menu.Item key="dividertTwo">
              <div className="Divider" />
            </Menu.Item>
            <Menu.Item key="user">
              <Dropdown
                trigger="click"
                overlay={
                  <Menu theme="dark" disabledOverflow>
                    <Menu.Item key="changePassword">
                      <Link to="/changepassword">
                        <Translate value="main.changePassword" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="logout">
                      <Link to="/" onClick={handleLogout}>
                        <Translate value="main.logout" />
                      </Link>
                    </Menu.Item>
                  </Menu>
                }
              >
                <div className="UserBlock">
                  <Profile color="currentColor" style={{ marginRight: 5 }} />
                  <Text isClipped numOfLines={1} color="currentColor">
                    {username}
                  </Text>
                  <span className="dropdown-icon" />
                </div>
              </Dropdown>
            </Menu.Item>
          </Menu>
        </div>
      )}
      <Layout noHeader={isMounted} style={{ minWidth: '1440px' }}>
        <Layout.Page>
          <Switch>
            <Route
              path="/cyberpolygon/hunt/:name"
              component={CyberPolygonRule}
            />
            <Route
              path="/cyberpolygon/event/:name?"
              component={CyberPolygonEvent}
            />
            <ProtectedRoute
              path="/dashboard"
              component={Dashboard}
              pageName="dashboard"
            />
            <ProtectedRoute
              path="/incidents"
              component={IncidentList}
              pageName="incidentList"
            />
            <ProtectedRoute
              path="/inc/:incNumber"
              component={Incident}
              pageName="incident"
            />
            <ProtectedRoute
              path="/protected-systems/:systemId"
              component={ProtectedSystemCard}
              pageName="protectedSystemCard"
            />
            <ProtectedRoute
              path="/protected-systems"
              component={ProtectedSystems}
              pageName="protectedSystems"
            />
            <ProtectedRoute
              path="/reports/:tab?"
              component={Reports}
              pageName="reports"
            />
            <ProtectedRoute
              path="/changepassword"
              component={ChangePassword}
              pageName="changePassword"
            />
            <ProtectedRoute
              path="/log-sources/:tab?"
              component={LogSources}
              theme={theme}
              pageName="logSources"
            />
            <ProtectedRoute
              path="/cmdb/assets/:assetId"
              component={CmdbAssetPage}
              pageName="cmdbAsset"
              user={user}
              locale={locale}
              accessEdit={false}
            />
            <ProtectedRoute
              path="/cmdb/assets"
              component={CmdbAsset}
              pageName="cmdbAssets"
              user={user}
              locale={locale}
              accessEdit={false}
            />
            <ProtectedRoute
              path="/docs/:tab?"
              component={Docs}
              pageName="articles"
            />
            <ProtectedRoute
              path="/instruction/:id"
              component={Instruction}
              pageName="instruction"
            />
            <ProtectedRoute
              path="/responseInstruction/:id"
              component={ResponseInstruction}
              pageName="instruction"
            />
            <ProtectedRoute
              path="/article/:id"
              component={Article}
              pageName="article"
            />
            <ProtectedRoute
              path="/rule/:id"
              component={RulePage}
              pageName="rule"
            />
            <ProtectedRoute path="/files" component={FilePreview} />
            <ProtectedRoute
              path="/issues/:model/:type/:id?"
              component={IssuePage}
              chat={Chat}
              pageName="issues.tasks"
              locale={locale}
            />
            <Redirect to={defaultRoute} />
          </Switch>
        </Layout.Page>
      </Layout>
      <DownloadNotification />
    </div>
  );
}

MainComponent.displayName = 'MainComponent';
