import React from 'react';
import isEqual from 'react-fast-compare';
import { I18n, Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';

import { Header } from '@bizone/ui-bundle/esm/Header';
import { List } from '@bizone/ui-bundle/esm/List';

import './Card.scss';
import { getDateInFullDateAndTimeFormat } from '@soc/kit/date';
import { useLanguage } from 'combinezone/utils';

export const Card = React.memo(({ onClick, version }) => {
  const { comment, date_created: created } = version;
  const { language } = useLanguage();

  return (
    <div className="VersionCard" onClick={() => onClick(version)}>
      <div className="VersionCard-Header">
        <Header size={14}>
          <Link to="#">
            <Translate
              value="instructionsCard.versions.version"
              version={getDateInFullDateAndTimeFormat(language, created)}
            />
          </Link>
        </Header>
      </div>
      <div className="VersionCard-Body">
        <List
          data={{
            [I18n.t('instructionsCard.versions.comment')]: comment,
          }}
          cls="VersionCard-List"
          keysWidth={95}
        />
      </div>
    </div>
  );
}, isEqual);
