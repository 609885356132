import React from 'react';
import { I18n } from 'react-redux-i18n';

import { Icon } from '@bizone/ui-bundle/esm/Icon';

import { TagGroup } from '@common/soc-react-kit';
import Tags from '@soc/alerts/src/components/DataGrid/Column/Tags/Cell/index';
import { getDateInFullDateAndTimeFormat } from '@soc/kit/date';
import { Popover, Rate } from 'antd';
import { Tag } from 'combinezone/core';

import AssignedEditor from 'components/AssignedEditor';

import { RuleContent } from '../components/IncidentPage/RuleContent';
import AttachmentsContainer from '../containers/AttachmentsContainer';
import {
  ACTIVITY_DETECTED,
  ATTACHMENTS_FIELD,
  CATEGORY_FIELD,
  CREATE_FIELD,
  CUSTOMER_ASSIGNEE,
  CUSTOMER_ASSIGNEE_LAST_TIME,
  INSTRUCTIONS_ATTACHMENTS,
  INSTRUCTIONS_CREATED,
  INSTRUCTIONS_NAME,
  INSTRUCTIONS_SENSORS,
  INSTRUCTIONS_TAGS,
  INSTRUCTIONS_UPDATED,
  KEY_FIELD,
  MITRE_FIELD,
  PRIORITY_FIELD,
  RATING_COMMENT,
  RATING_STARS,
  RESOLUTION_DATE_FIELD,
  RESOLUTION_NAME_FIELD,
  RULES_FIELD,
  SECONDARY_CATEGORY_FIELD,
  SERVICE_FIELD,
  SLA_FIELD,
  STATUS_DESCRIPTION_FIELD,
  STATUS_FIELD,
  SUBMITER_FIELD,
  SYSTEM_FIELD,
  UPDATE_FIELD,
  TAGS,
  ID_FIELD,
} from '../services/api';
import { getMomentObject } from '@common/soc-react-kit/src/utils/momentHelpers';

const tinycolor = require('tinycolor2');

export const priorityIconProps = {
  critical: { glyph: 'multi-angle-up', color: '#e0281b' },
  high: { glyph: 'arrow-up', color: '#eb6f1c' },
  medium: { glyph: 'level-medium', color: '#ebaf09' },
  low: { glyph: 'arrow-down', color: '#57ad37' },
};

export const statusIconProps = {
  Canceled: { glyph: 'ban', color: 'rgba(0, 0, 0, 0.4)' },
  Closed: { glyph: 'close-circle-outline', color: 'rgba(0, 0, 0, 0.4)' },
  Completed: {
    glyph: 'check-circle-outline',
    color: 'var(--basis-colors-icon-active-normal)',
  },
  'Waiting for support': {
    glyph: 'clock',
    color: 'var(--basis-colors-icon-active-normal)',
  },
  'Work in progress': {
    glyph: 'start-progress',
    color: 'var(--basis-colors-icon-active-normal)',
  },
};

export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function getDefaultDashboard() {
  const layout = [
    { w: 10, h: 10, minH: 10, maxH: 10, x: 6, y: 0, i: 'statusWidget' },
    { w: 6, h: 10, minH: 10, maxH: 10, x: 0, y: 0, i: 'priorityWidget' },
    {
      w: 9,
      h: 10,
      minH: 10,
      maxH: 10,
      x: 7,
      y: 10,
      i: 'registrationDynamicsWidget',
    },
    { w: 7, h: 10, minH: 10, maxH: 10, x: 0, y: 10, i: 'categoryWidget' },
    { w: 8, h: 10, minH: 10, maxH: 10, x: 0, y: 20, i: 'topDomainsWidget' },
    { w: 8, h: 10, minH: 10, maxH: 10, x: 8, y: 20, i: 'topIpAddressesWidget' },
    {
      w: 16,
      h: 10,
      minH: 10,
      maxH: 10,
      minW: 16,
      maxW: 16,
      x: 0,
      y: 30,
      i: 'epsWidget',
    },
  ];

  const statusWidget = {
    id: 'statusWidget',
    type: 'bar',
  };

  const priorityWidget = {
    id: 'priorityWidget',
    type: 'pie',
  };

  const registrationDynamicsWidget = {
    id: 'registrationDynamicsWidget',
    type: 'line',
  };

  const categoryWidget = {
    id: 'categoryWidget',
    type: 'horizontalBar',
  };

  const slaWidget = {
    id: 'slaWidget',
    type: 'gauge',
  };

  const topDomainsWidget = {
    id: 'topDomainsWidget',
    type: 'horizontalBar',
  };

  const epsWidget = {
    id: 'epsWidget',
    type: 'bar',
  };

  return {
    layout,
    widgets: {
      [statusWidget.id]: statusWidget,
      [priorityWidget.id]: priorityWidget,
      [registrationDynamicsWidget.id]: registrationDynamicsWidget,
      [categoryWidget.id]: categoryWidget,
      [topDomainsWidget.id]: topDomainsWidget,
      [slaWidget.id]: slaWidget,
      [epsWidget.id]: epsWidget,
    },
  };
}

export function generateAmchartData(_data = [], chart) {
  const { colors, i18nField, max, sortWeights } = chart;

  const data = [..._data];

  // Prepare to sort
  const countsArr = data.map(({ item, value }) => {
    const dataObj = {
      category: i18nField ? I18n.t(`${i18nField}.${item}`) : item,
      filterValue: item,
      value,
    };

    if (typeof colors === 'object') {
      if (!(item in colors)) {
        colors[item] = tinycolor.random().toHexString();
      }
      dataObj.color = colors[item];
    }

    return dataObj;
  });

  // Sort
  if (sortWeights) {
    countsArr.sort(
      (first, second) =>
        sortWeights[first.category] - sortWeights[second.category],
    );
  } else {
    countsArr.sort((first, second) => second.value - first.value);
  }

  const res = max === undefined ? countsArr : countsArr.slice(0, max);
  return res.reverse();
}

export function generateAmchartTimelineData(data = [], settings, range) {
  const firstDay = range[0];
  const preData = [];

  for (
    let i = firstDay.valueOf();
    data[0] && i < data[0].item;
    i += data[0].timespan
  ) {
    preData.push({ item: i, value: 0, timespan: data[0].timespan });
  }

  return [...preData, ...data].map(({ item, timespan, value }) => ({
    date: item,
    value,
    timespan,
    config: {
      fill: '#00F',
      label: {
        fill: '#F00',
      },
    },
  }));
}

// eslint-disable-next-line func-names
export const instructionFormatter = new (function () {
  this.getSensors = (
    { [INSTRUCTIONS_SENSORS]: sensors = [] },
    hideUnnecessaryTags = false,
  ) => {
    const group =
      hideUnnecessaryTags && sensors.length > 3 ? (
        <TagGroup>
          {sensors.slice(0, 3).map((sensor, i) => (
            <Tag color="blue" key={i}>
              {sensor.name}
            </Tag>
          ))}
          <Tag key={3} color="blue">{`+${sensors.length - 3}`}</Tag>
        </TagGroup>
      ) : (
        <TagGroup>
          {sensors.map((sensor, i) => (
            <Tag color="blue" key={i}>
              {sensor.name}
            </Tag>
          ))}
        </TagGroup>
      );

    return sensors.length > 0 ? group : null;
  };

  this.getTags = (
    { [INSTRUCTIONS_TAGS]: tags = [] },
    hideUnnecessaryTags = false,
  ) => {
    const group =
      hideUnnecessaryTags && tags.length > 3 ? (
        <TagGroup>
          {tags.slice(0, 3).map((tag, i) => (
            <Tag key={i}>{tag}</Tag>
          ))}
          <Tag key={3}>{`+${tags.length - 3}`}</Tag>
        </TagGroup>
      ) : (
        <TagGroup>
          {tags.map((tag, i) => (
            <Tag key={i}>{tag}</Tag>
          ))}
        </TagGroup>
      );

    return tags.length > 0 ? group : null;
  };
  this.getAttachments = (instruction) =>
    Array.isArray(instruction[INSTRUCTIONS_ATTACHMENTS]) &&
    instruction[INSTRUCTIONS_ATTACHMENTS].length > 0 ? (
      <AttachmentsContainer
        files={instruction[INSTRUCTIONS_ATTACHMENTS].map((instruction) => ({
          ...instruction,
          name: instruction.filename,
        }))}
      />
    ) : null;

  this.mainInfoFormatter = (instruction, language) => ({
    mainInfo: {
      [I18n.t('instructionsCard.name')]: instruction[INSTRUCTIONS_NAME],
      [I18n.t('instructionsCard.lastUpdate')]: getDateInFullDateAndTimeFormat(
        language,
        instruction[INSTRUCTIONS_UPDATED],
      ),
      [I18n.t('instructionsCard.created')]: getDateInFullDateAndTimeFormat(
        language,
        instruction[INSTRUCTIONS_CREATED],
      ),
      [I18n.t('instructionsCard.sensors')]: this.getSensors(instruction),
      [I18n.t('instructionsCard.tags')]: this.getTags(instruction),
    },
  });
})();

export function arrayToOptions(arr) {
  return arr.map((value) => ({
    value,
    content: value,
  }));
}

// eslint-disable-next-line func-names
export const incidentFormatter = new (function () {
  const getSources = (source = [], hideUnnecessaryTags = false) => {
    const group =
      hideUnnecessaryTags && source.length > 3 ? (
        <TagGroup>
          {source.slice(0, 3).map((array, i) => (
            <Tag key={i}>{array.filter((el) => el !== '').join('@')}</Tag>
          ))}
          <Tag key={3}>{`+${source.length - 3}`}</Tag>
        </TagGroup>
      ) : (
        <TagGroup>
          {source.map((array, i) => (
            <Tag key={i}>{array.filter((el) => el !== '').join('@')}</Tag>
          ))}
        </TagGroup>
      );

    return source.length > 0 ? group : null;
  };

  this.getCategories = (
    { [CATEGORY_FIELD]: category = [] },
    tagGroupProps = {},
    tagProps = {},
    hideUnnecessaryTags = false,
  ) => {
    const group =
      hideUnnecessaryTags && category.length > 3 ? (
        <TagGroup {...tagGroupProps}>
          {category.slice(0, 3).map((category, i) => (
            <Tag key={i} {...tagProps}>
              {category.title}
            </Tag>
          ))}
          <Tag key={3} {...tagProps}>{`+${category.length - 3}`}</Tag>
        </TagGroup>
      ) : (
        <TagGroup {...tagGroupProps}>
          {category.map((category, i) => (
            <Tag key={i} {...tagProps}>
              {category.title}
            </Tag>
          ))}
        </TagGroup>
      );

    return category.length > 0 ? group : null;
  };

  this.getMitreTags = (
    { [MITRE_FIELD]: mitre = {} },
    {
      hideUnnecessaryTags = false,
      mitreTechniques = {},
      tagGroupProps = {},
      tagProps = {},
    } = {},
  ) => {
    if (
      Object.keys(mitre || {}).length === 0 ||
      Object.keys(mitreTechniques).length === 0
    ) {
      return null;
    }

    const techniques = unique(
      Object.keys(mitre)
        .map((tacticId) =>
          mitre[tacticId].map(
            (techniqueId) =>
              `${techniqueId}: ${mitreTechniques[techniqueId].name}`,
          ),
        )
        .flat(),
    );

    return hideUnnecessaryTags && techniques.length > 3 ? (
      <TagGroup {...tagGroupProps}>
        {techniques.slice(0, 3).map((technique, i) => (
          <Tag key={i} {...tagProps}>
            {technique}
          </Tag>
        ))}
        <Tag key={3} {...tagProps}>{`+${techniques.length - 3}`}</Tag>
      </TagGroup>
    ) : (
      <TagGroup {...tagGroupProps}>
        {techniques.map((technique, i) => (
          <Tag key={i} {...tagProps}>
            {technique}
          </Tag>
        ))}
      </TagGroup>
    );
  };

  this.getRulesTags = (
    { [RULES_FIELD]: rules = [] },
    getPopupContainer,
    hideUnnecessaryTags = false,
  ) => {
    const group =
      hideUnnecessaryTags && rules.length > 3 ? (
        <TagGroup>
          {rules.slice(0, 3).map((rule, i) => (
            <span className="Tag" key={i}>
              <div className="RowWithHelp">
                <span>{rule[I18n.t('incCard.RuleList.ruleField')]}</span>
                <Popover
                  content={<RuleContent ruleData={rule} />}
                  getPopupContainer={getPopupContainer}
                  trigger="click"
                >
                  <span className="IconContainer">
                    <Icon glyph="help" />
                  </span>
                </Popover>
              </div>
            </span>
          ))}
          <Tag key={3}>{`+${rules.length - 3}`}</Tag>
        </TagGroup>
      ) : (
        <TagGroup>
          {rules.map((rule, i) => (
            <span className="Tag" key={i}>
              <div className="RowWithHelp">
                <span>{rule[I18n.t('incCard.RuleList.ruleField')]}</span>
                <Popover
                  content={<RuleContent ruleData={rule} />}
                  getPopupContainer={getPopupContainer}
                  trigger="click"
                >
                  <span className="IconContainer">
                    <Icon glyph="help" />
                  </span>
                </Popover>
              </div>
            </span>
          ))}
        </TagGroup>
      );

    return rules.length > 0 ? group : null;
  };

  this.getPriorityRow = (priority = '') => (
    <div className="RowWithIcon">
      <Icon size={20} {...priorityIconProps[priority.toLowerCase()]} />
      {priority}
    </div>
  );
  this.getStatusRow = (status = '') => (
    <div className="RowWithIcon">
      <Icon size={24} {...statusIconProps[status]} />
      {status}
    </div>
  );

  this.getSLABlock = (
    {
      [PRIORITY_FIELD]: priority = '',
      [SLA_FIELD]: sla,
      [STATUS_FIELD]: status = '',
    } = {},
    language,
    slaVerbose = false,
  ) => ({
    [I18n.t('incCard.priority')]: this.getPriorityRow(priority),
    [I18n.t('incCard.status')]: this.getStatusRow(status),
    [slaVerbose ? I18n.t('incCard.slaVerbose') : I18n.t('incCard.sla')]: {
      text: sla
        ? getDateInFullDateAndTimeFormat(language, sla)
        : I18n.t('incCard.noSLA'),
      help: I18n.t('incCard.slaTooltip'),
    },
  });

  this.mainInfoFormatter = (
    incident = {},
    language,
    { mitreTactics = {}, mitreTechniques = {}, slaVerbose = false } = {},
  ) => {
    const mainInfo = {
      [I18n.t('incCard.type')]: incident.issuetype_ref?.title,
      [I18n.t('incCard.number')]: incident[KEY_FIELD],
      [I18n.t('incCard.created')]: getDateInFullDateAndTimeFormat(
        language,
        incident[CREATE_FIELD],
      ),
      [I18n.t('incCard.lastUpdate')]: getDateInFullDateAndTimeFormat(
        language,
        incident[UPDATE_FIELD],
      ),
    };
    if (incident[RESOLUTION_DATE_FIELD]) {
      mainInfo[I18n.t('incCard.resolutionDate')] =
        getDateInFullDateAndTimeFormat(
          language,
          incident[RESOLUTION_DATE_FIELD],
        );
      mainInfo[I18n.t('incCard.resolutionName')] =
        incident[RESOLUTION_NAME_FIELD]?.title || null;
    }

    const mainInfoForIncPage = {
      [I18n.t('incCard.type')]: incident.issuetype_ref?.title,
      [I18n.t('incCard.service')]: incident[SERVICE_FIELD],
      [I18n.t('incCard.infoSystem')]: incident[SYSTEM_FIELD],
      [I18n.t('incCard.basicCategory')]: incident[CATEGORY_FIELD]?.map(
        (el) => el.title,
      ).join('; '),
      [I18n.t('incCard.secondaryCategory')]: incident[
        SECONDARY_CATEGORY_FIELD
      ]?.map((el) => el.title).join('; '),
      [I18n.t('incCard.tags')]: (
        <Tags
          isWrap
          testId={`${incident[ID_FIELD]}-panel-tags`}
          tags={incident[TAGS]}
        />
      ),
    };

    const details = {
      [I18n.t('incCard.infoSystem')]: incident[SYSTEM_FIELD],
      [I18n.t('incCard.initiator')]: incident[SUBMITER_FIELD],
      [I18n.t('incCard.service')]: incident[SERVICE_FIELD],
      [I18n.t('incCard.category')]: this.getCategories(incident),
      [I18n.t('incCard.tags')]: (
        <Tags
          isWrap
          testId={`${incident[ID_FIELD]}-panel-tags`}
          tags={incident[TAGS]}
        />
      ),
      [I18n.t('incCard.shortDescription')]: incident[STATUS_DESCRIPTION_FIELD],
      [I18n.t('incCard.activityDetected')]: getSources(
        incident[ACTIVITY_DETECTED],
      ),
      [I18n.t('incCard.rules')]: this.getRulesTags(incident),
      [I18n.t('incCard.attachments')]:
        incident[ATTACHMENTS_FIELD] &&
        incident[ATTACHMENTS_FIELD].length > 0 ? (
          <AttachmentsContainer files={incident[ATTACHMENTS_FIELD]} />
        ) : null,
    };

    const detailsInfoForIncPage = {
      [I18n.t('incCard.createdDate')]: getDateInFullDateAndTimeFormat(
        language,
        incident[CREATE_FIELD],
      ),
      [I18n.t('incCard.lastUpdateDate')]: getDateInFullDateAndTimeFormat(
        language,
        incident[UPDATE_FIELD],
      ),
      [I18n.t('incCard.initiator')]: incident[SUBMITER_FIELD],
      [I18n.t('incCard.resolutionName')]:
        incident[RESOLUTION_NAME_FIELD]?.title || null,
      [I18n.t('incCard.shortDescription')]: incident[STATUS_DESCRIPTION_FIELD],
      [I18n.t('incCard.activityDetected')]: getSources(
        incident[ACTIVITY_DETECTED],
      ),
      [I18n.t('incCard.shortDescription')]: incident[STATUS_DESCRIPTION_FIELD],
      [I18n.t('incCard.rules')]: this.getRulesTags(incident),
      [I18n.t('incCard.attachments')]:
        incident[ATTACHMENTS_FIELD] &&
        incident[ATTACHMENTS_FIELD].length > 0 ? (
          <AttachmentsContainer files={incident[ATTACHMENTS_FIELD]} />
        ) : null,
    };

    const sla = this.getSLABlock(incident, language, slaVerbose);

    const assignRows = {
      [I18n.t('incCard.assigned')]: (
        <AssignedEditor
          keyIncident={incident.key}
          user={incident[CUSTOMER_ASSIGNEE]}
          system={incident[SYSTEM_FIELD]}
        />
      ),
      [I18n.t('incCard.assignedTime')]: incident[CUSTOMER_ASSIGNEE_LAST_TIME]
        ? getDateInFullDateAndTimeFormat(
            language,
            incident[CUSTOMER_ASSIGNEE_LAST_TIME],
          )
        : '-',
    };

    const result = {
      mainInfo,
      mainInfoForIncPage,
      details,
      detailsInfoForIncPage,
      sla,
      assignRows,
    };

    if (
      incident[MITRE_FIELD] &&
      Object.keys(incident[MITRE_FIELD]).length > 0 &&
      Object.keys(mitreTactics).length > 0 &&
      Object.keys(mitreTechniques).length > 0
    ) {
      result.mitre = Object.keys(incident[MITRE_FIELD]).reduce(
        (res, tacticId) => {
          const hasTactic = mitreTactics[tacticId];
          const techniquesIdsDefined = incident[MITRE_FIELD][tacticId].filter(
            (techniqueId) => !!mitreTechniques[techniqueId],
          );

          if (hasTactic && techniquesIdsDefined?.length) {
            res[mitreTactics[tacticId].name] = (
              <TagGroup>
                {techniquesIdsDefined.map((techniqueId, i) => (
                  <Tag
                    key={i}
                  >{`${techniqueId}: ${mitreTechniques[techniqueId].name}`}</Tag>
                ))}
              </TagGroup>
            );
          }
          return res;
        },
        {},
      );
    }

    if (incident[RATING_STARS]) {
      result.rating = {
        [I18n.t('incCard.rating.stars')]: (
          <Rate value={incident[RATING_STARS]} disabled />
        ),
        [I18n.t('incCard.rating.comment')]: incident[RATING_COMMENT],
      };
    }

    return result;
  };
})();

export const getTechniqueTags = (techniques, { withLinks = true } = {}) => {
  return techniques.map((technique) => (
    <Tag key={technique.technique_id}>
      {withLinks ? (
        <a
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          style={{ pointerEvents: 'auto' }}
          href={technique.url}
          rel="noreferrer"
        >
          {technique.fullName}
        </a>
      ) : (
        technique.fullName
      )}
    </Tag>
  ));
};

export const getMitre = (mitre, allTechniques, maxVisibleCount = 3) => {
  mitre = mitre || {};

  if (
    Object.keys(mitre).length <= 0 ||
    Object.keys(allTechniques).length <= 0
  ) {
    return null;
  }
  const techniquesSet = new Set();

  Object.values(mitre).forEach((techniques) =>
    techniques.forEach((id) => techniquesSet.add(id)),
  );

  const techniques = Array.from(techniquesSet)
    .map((id) => allTechniques[id])
    .filter(Boolean);

  if (techniques.length > maxVisibleCount) {
    const popoverContent = (
      <TagGroup>{getTechniqueTags(techniques.slice(maxVisibleCount))}</TagGroup>
    );

    return (
      <TagGroup>
        {getTechniqueTags(techniques.slice(0, maxVisibleCount))}
        <Popover content={popoverContent} overlayStyle={{ maxWidth: 600 }}>
          <Tag>+{techniques.length - maxVisibleCount}</Tag>
        </Popover>
      </TagGroup>
    );
  }

  return techniques.length > 0 ? (
    <TagGroup>{getTechniqueTags(techniques, {})}</TagGroup>
  ) : null;
};

export function unique(array) {
  const result = [];

  for (const value of array) {
    if (!result.includes(value)) {
      result.push(value);
    }
  }

  return result;
}

export function getQueryParams(search = '') {
  return search
    .slice(1)
    .split('&')
    .reduce((obj, item) => {
      const [key, value] = item.split('=');
      obj[key] = value;
      return obj;
    }, {});
}

export function getFilterQueryParams(search) {
  const dateRangeFields = [CREATE_FIELD, RESOLUTION_DATE_FIELD];
  const params = new URLSearchParams(search);
  const result = {};

  for (const key of params.keys()) {
    const param = params.get(key).split(',');

    if (dateRangeFields.includes(key)) {
      if (param.length === 2) {
        result[key] = [
          getMomentObject(param[0]).format('x'),
          getMomentObject(param[1]).format('x'),
        ];
      }
    } else if (key === 'ordering') {
      let field = param[0];
      let type = 'ASC';

      if (field[0] === '-') {
        field = field.slice(1);
        type = 'DESC';
      }
      result[key] = { [field]: type };
    } else {
      result[key] = param;
    }
  }
  return result;
}

export function getFilterQueryStr(filter, ignoreFields = []) {
  const dateRangeFields = [CREATE_FIELD, RESOLUTION_DATE_FIELD];

  const result = Object.keys(filter).reduce((accum, key) => {
    if (ignoreFields.includes(key)) {
      return accum;
    }

    const param = dateRangeFields.includes(key)
      ? [filter[key][0].format('x'), filter[key][1].format('x')]
      : filter[key];
    if (key === 'ordering') {
      const ordKey = Object.keys(param)[0];

      return `${accum}&${key}=${ordKey}__${param[ordKey]}`;
    }
    return `${accum}&${key}=${Array.isArray(param) ? param.join('__') : param}`;
  }, '?');

  return result.replace(/^\?&/, '?');
}

export const createPeriodAndCrontab = (periodicity, time) => {
  let period;
  let crontab;

  switch (periodicity) {
    case 'everyDay':
      period = '1';
      crontab = {
        minute: Number(time.minute()),
        hour: Number(time.hour()),
        day_of_week: '*',
        day_of_month: '*',
        month_of_year: '*',
      };
      break;

    case 'everyWeek':
      period = '7';
      crontab = {
        minute: Number(time.minute()),
        hour: Number(time.hour()),
        day_of_week: Number(time.day()),
        day_of_month: '*',
        month_of_year: '*',
      };
      break;

    case 'twiceAMonth':
      period = '14';
      crontab = {
        minute: Number(time.minute()),
        hour: Number(time.hour()),
        day_of_week: '*',
        day_of_month: `${time.date()},${
          time.date() + 14 > time.daysInMonth()
            ? time.date() + 14 - time.daysInMonth()
            : time.date() + 14
        }`,
        month_of_year: '*',
      };
      break;

    case 'onceAMonth':
      period = Number(time.daysInMonth());
      crontab = {
        minute: Number(time.minute()),
        hour: Number(time.hour()),
        day_of_week: '*',
        day_of_month: Number(time.date()),
        month_of_year: '*',
      };
      break;

    default:
      break;
  }

  return { period, crontab };
};

export const getPeriodFromCrontab = (crontab) => {
  if (!crontab) {
    return 'none';
  }

  if (
    crontab.day_of_week === '*' &&
    crontab.day_of_month === '*' &&
    crontab.month_of_year === '*'
  ) {
    return 'everyDay';
  }

  if (crontab.day_of_month === '*' && crontab.month_of_year === '*') {
    return 'everyWeek';
  }

  if (
    crontab.day_of_week === '*' &&
    crontab.month_of_year === '*' &&
    crontab.day_of_month.length > 2
  ) {
    return 'twiceAMonth';
  }

  if (crontab.day_of_week === '*' && crontab.month_of_year === '*') {
    return 'onceAMonth';
  }
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getFormBodyFromJson = (json) => {
  const formData = new FormData();
  Object.entries(json).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => formData.append(key, item));
    } else if (value !== undefined) {
      formData.append(key, value);
    }
  });
  return formData;
};

export const tooLongStringFormatter = (text, maxLength, wrapper) => {
  if (!text) {
    return;
  }

  const content = wrapper ? wrapper(text) : text;

  const findRightEndOfStr = (str, maxLength) => {
    let shownText = str.substring(0, maxLength - 1);

    if (str[shownText.length] !== ' ') {
      const indexOfLastSpace = shownText.lastIndexOf(' ');
      if (indexOfLastSpace !== -1) {
        shownText = shownText.substring(0, indexOfLastSpace);
      }
    }

    return shownText;
  };

  if (Array.isArray(text)) {
    if (text.join(' ').length > maxLength) {
      return (
        <Popover content={content}>
          <span>{findRightEndOfStr(text.join(' '), maxLength)}…</span>
        </Popover>
      );
    }
  }

  if (text?.length > maxLength) {
    return (
      <Popover content={content}>
        <span>{findRightEndOfStr(text, maxLength)}…</span>
      </Popover>
    );
  }

  return content;
};

export const setFilterFromQuerysringGeneral = ({
  querystring,
  setFilter,
  setLocale,
  setSearch,
  setSort,
}) => {
  if (!querystring) {
    return;
  }
  const params = getFilterQueryParams(querystring);

  const filters = Object.entries(params).reduce((filters, [key, value]) => {
    if (key.slice(-5) === `__gte`) {
      const newKey = key.slice(0, -5);
      if (!filters[newKey]) {
        filters[newKey] = [];
      }
      filters[key.slice(0, -5)][0] = getMomentObject(Number(value[0]));
    } else if (key.slice(-5) === `__lte`) {
      const newKey = key.slice(0, -5);
      if (!filters[newKey]) {
        filters[newKey] = [];
      }
      filters[key.slice(0, -5)][1] = getMomentObject(Number(value[0]));
    } else if (key.slice(-9) === '__isempty') {
      filters[key.slice(0, -9)] = value[0].toLowerCase();
    } else if (key.slice(-4) === '__in') {
      filters[key.slice(0, -4)] = value;
    } else if (key === 'ordering') {
      setSort?.(value);
    } else if (key === 'search') {
      setSearch?.(value[0]);
    } else if (key === 'language') {
      setLocale?.(value[0]);
    } else if (key === 'gossopka_id__isnull') {
      filters.gossopka_id = value;
    } else {
      filters[key] = value;
    }

    return filters;
  }, {});
  Object.entries(filters).forEach(([key, value]) => setFilter(key, value));
};

export const setFilterFromQuerystringRules = ({
  querystring,
  setFilter,
  setLocale,
  setSearch,
  setSort,
}) => {
  if (!querystring) {
    return;
  }
  const params = getFilterQueryParams(querystring);

  const filters = Object.entries(params).reduce((filters, [key, value]) => {
    if (key.slice(-5) === `__gte`) {
      const newKey = key.slice(0, -5);
      if (!filters[newKey]) {
        filters[newKey] = [];
      }
      filters[key.slice(0, -5)][0] = getMomentObject(value[0]);
    } else if (key.slice(-5) === `__lte`) {
      const newKey = key.slice(0, -5);
      if (!filters[newKey]) {
        filters[newKey] = [];
      }
      filters[key.slice(0, -5)][1] = getMomentObject(value[0]);
    } else if (key.slice(-9) === '__isempty') {
      filters[key.slice(0, -9)] = value[0].toLowerCase();
    } else if (key.slice(-4) === '__in') {
      filters[key.slice(0, -4)] = value;
    } else if (key === 'ordering') {
      setSort?.(value);
    } else if (key === 'search') {
      setSearch?.(value[0]);
    } else if (key === 'language') {
      setLocale?.(value[0]);
    } else if (key === 'gossopka_id__isnull') {
      filters.gossopka_id = value;
    } else {
      filters[key] = value;
    }

    return filters;
  }, {});
  Object.entries(filters).forEach(([key, value]) => setFilter(key, value));
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const instantDownloadSize = (mb = 50) => {
  return mb * 1024 ** 2;
};

export function mitreFormatter(key, value) {
  if (value !== null) {
    return { key, value };
  }
}

export const getFileDataUrlPromise = (file) =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onloadend = (dataUrl) => resolve(dataUrl.target.result);
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });

export const mergeClassNames = (...arr) => {
  const classes = arr
    .filter((item) => typeof item === 'string')
    .map((item) => item.trim());

  return classes.join(' ');
};
