import { api, SOC_URL } from '@soc/kit/api';

import { resolutionFetchAbortControllerKey } from '../constants';
import { Resolutions, ResolutionsFetch } from '../types';

export const fetchResolutions: ResolutionsFetch = ({ issueType, search }) => {
  const trimmedSearch = search.trim();
  const apiSearch = trimmedSearch
    ? `&q=title ~ "${trimmedSearch}" or id ~ "${trimmedSearch}"`
    : '';

  return api<Resolutions>(
    `${SOC_URL}issues/${issueType}/resolutions/?limit=30${apiSearch}`,
    {
      method: 'get',
      abortControllerKey: resolutionFetchAbortControllerKey,
    },
  );
};
