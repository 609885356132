import { createAction } from 'redux-api-middleware';

import * as types from './actionTypes';

export const checkAlertAssign = () => (dispatch, getState) => {
  return dispatch(
    createAction({
      endpoint: getState().incidentPage.alerts.selected.data._source,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      types: [
        types.CHECK_ALERT_ASSIGNEE_REQUEST,
        types.CHECK_ALERT_ASSIGNEE_SUCCESS,
        types.CHECK_ALERT_ASSIGNEE_FAILURE,
      ],
    }),
  );
};
