import { getMomentObject } from '@common/soc-react-kit/src/utils/momentHelpers';
import { createAction } from 'redux-api-middleware';

import { showFilters } from 'store/reducers/filters/actions';
import { RSAActionProp } from 'store/reducers/types';

import { isAuthenticated, withHeaders } from '..';
import {
  DASHBOARD_ENDPOINT,
  encodeIncidentsFilter,
  INCIDENTS_ENTITY,
} from '../../../services/api';
import widgets from '../../../services/widgets';
import * as utils from '../../../utils/helpers';
import { NEW_DAY_STARTED } from '../filters/actionTypes';

import * as types from './actionTypes';

export const setDashboardUpdateTimer = (customInterval) => {
  return (dispatch, getState) => {
    const state = getState();

    if (!isAuthenticated(state)) {
      return dispatch(unsetDashboardUpdateTimer());
    }

    const { updateTimer } = state.dashboard;
    const updateInterval = customInterval || state.dashboard.updateInterval;

    clearInterval(updateTimer);
    const newUpdateTimer = setInterval(
      () => dispatch(fetchDashboardChanges()),
      updateInterval,
    );

    return dispatch({
      type: types.SET_DASHBOARD_UPDATE_TIMER,
      payload: { newUpdateTimer },
    });
  };
};

export const unsetDashboardUpdateTimer = () => {
  return (dispatch, getState) => {
    const {
      dashboard: { updateTimer },
    } = getState();

    clearInterval(updateTimer);

    return dispatch({
      type: types.UNSET_DASHBOARD_UPDATE_TIMER,
    });
  };
};

export const getDashboardLayout = () => {
  return (dispatch, getState) => {
    let dashboard = utils.getDefaultDashboard();
    const savedDashboardLayout = localStorage.getItem('dashboardLayout');
    const savedDashboardWidgets = localStorage.getItem('dashboardWidgets');

    if (savedDashboardLayout && savedDashboardWidgets) {
      dashboard.layout = JSON.parse(savedDashboardLayout);
      dashboard.widgets = JSON.parse(savedDashboardWidgets);
    } else {
      localStorage.setItem('dashboardLayout', JSON.stringify(dashboard.layout));
      localStorage.setItem(
        'dashboardWidgets',
        JSON.stringify(dashboard.widgets),
      );
    }

    dashboard = widgets.checkUserDashboard(dashboard);
    dashboard = widgets.checkAllWidgetExists(
      dashboard,
      getState().dashboard.cols,
    );

    return dispatch({
      type: types.GET_DASHBOARD,
      payload: dashboard,
    });
  };
};

export const fetchDashboardData = () => {
  return (dispatch, getState) => {
    dispatch(unsetDashboardUpdateTimer()); // clear update timer

    const state = getState();
    const {
      incidents: { dateRange, ordering, selected: filter },
    } = state.filters;
    const startTimestamp = dateRange[0].startOf('day').format('x');
    const endTimestamp = dateRange[1].endOf('day').format('x');
    const timestamp = Date.now();

    const params = encodeIncidentsFilter(
      filter,
      startTimestamp,
      endTimestamp,
      ordering,
    );

    return dispatch(fetchAnalytics(params, timestamp)).finally(() =>
      dispatch(setDashboardUpdateTimer()),
    );
  };
};

export const fetchDashboardChanges = () => {
  return (dispatch, getState) => {
    dispatch(unsetDashboardUpdateTimer()); // clear update timer

    const {
      dashboard: { lastUpdateTime },
      filters: {
        incidents: { dateRange: currentDateRange },
      },
    } = getState();

    if (
      currentDateRange[1]
        .startOf('day')
        .isBefore(getMomentObject(new Date()).startOf('day')) &&
      lastUpdateTime
        .startOf('day')
        .isBefore(getMomentObject(new Date()).startOf('day'))
    ) {
      // New day started
      dispatch({
        type: NEW_DAY_STARTED,
      });
    }

    const state = getState();
    const {
      incidents: { dateRange, ordering, selected: filter },
    } = state.filters;
    const startTimestamp = dateRange[0].startOf('day').format('x');
    const endTimestamp = dateRange[1].endOf('day').format('x');
    const timestamp = Date.now();

    const analyticsParams = encodeIncidentsFilter(
      filter,
      startTimestamp,
      endTimestamp,
      ordering,
    );

    dispatch(fetchAnalytics(analyticsParams, timestamp, true)).finally(() =>
      dispatch(setDashboardUpdateTimer()),
    );
  };
};

const fetchAnalytics = (params, timestamp, isUpdate = false) => {
  return createAction({
    endpoint: `${DASHBOARD_ENDPOINT}${params}&get_prev=True`,
    method: 'GET',
    headers: withHeaders({ 'Content-Type': 'application/json' }),
    types: [
      {
        type: types.FETCH_ANALYTICS_REQUEST,
        meta: { timestamp, isUpdate },
      },
      {
        type: types.FETCH_ANALYTICS_SUCCESS,
        meta: { timestamp, isUpdate },
      },
      {
        type: types.FETCH_ANALYTICS_FAILURE,
        meta: { timestamp, isUpdate },
      },
    ],
    isAbortOldRequests: true,
  } as RSAActionProp);
};

export const setGridLayout = (layout) => {
  return (dispatch) => {
    dispatch({
      type: types.EDIT_DASHBOARD_SET_GRID_LAYOUT,
      payload: { layout },
    });

    dispatch(saveDashboard());
  };
};

export const saveDashboard = () => {
  return (dispatch, getState) => {
    const state = getState();
    localStorage.setItem(
      'dashboardLayout',
      JSON.stringify(state.dashboard.layout),
    );
    localStorage.setItem(
      'dashboardWidgets',
      JSON.stringify(state.dashboard.widgets),
    );
  };
};

export const dashboardWidgetsMenuCheckboxClick = (widgetId, checkedValue) => {
  return (dispatch) => {
    return dispatch({
      type: types.DASHBOARD_WIDGETS_MENU_CHECKBOX_CLICK,
      payload: { widgetId, checkedValue },
    });
  };
};

export const deleteWidget = (widgetId) => {
  return (dispatch) => {
    dispatch({
      type: types.DELETE_WIDGET,
      payload: widgetId,
    });

    dispatch(saveDashboard());
  };
};

export const editWidgetMenuSettings = (widgetId, widgetSettings) => {
  return (dispatch) => {
    dispatch({
      type: types.EDIT_WIDGET_MENU_SUBMIT,
      payload: { widgetId, widgetSettings },
    });

    dispatch(saveDashboard());
  };
};

export const onChartFontsLoadingSuccess = () => ({
  type: types.CHART_FONTS_LOADING_SUCCESS,
});

export const dispatchShowFilters = (entity = INCIDENTS_ENTITY) => {
  return (dispatch) => {
    return dispatch(showFilters(entity));
  };
};
