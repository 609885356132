import React, { useState, useCallback, useEffect } from 'react';
import { Translate, I18n } from 'react-redux-i18n';

import styled from 'styled-components';

import { Button } from '@bizone/ui-bundle/esm/Button';
import { Form } from '@bizone/ui-bundle/esm/Form';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { Icon } from '@bizone/ui-bundle/esm/Icon';
import { Input } from '@bizone/ui-bundle/esm/Input';
import { Select } from '@bizone/ui-bundle/esm/Select';
import { Textarea } from '@bizone/ui-bundle/esm/Textarea';

import { FocusButton } from '@common/soc-react-kit';
import { Modal, notification } from 'antd';
import { Text, Flex, Datepicker } from 'combinezone/core';
import { getMomentObject } from '@common/soc-react-kit/src/utils/momentHelpers';

import {
  SHEDULE_ID,
  SHEDULE_NAME,
  SHEDULE_EMAILS,
  SHEDULE_SYSTEMS,
  SHEDULE_CRONTAB,
  SHEDULE_START_TIME,
} from '../../../services/api';
import bizoneNotification from '../../Notification';

import './EditModal.scss';
import { getDateInFullDateAndTimeFormat } from '@soc/kit/date';

const DateContainer = styled(Flex)`
  width: 250px;
`;

const dropdownProps = {
  usePortal: false,
};

export const EditShedule = ({
  createPeriodAndCrontab,
  editReport,
  firstFetch,
  getPeriodFromCrontab,
  isLoading,
  isOpen,
  locale,
  onCancel,
  shedule,
  systems,
  validateEmail,
}) => {
  const [start, setStart] = useState('');
  const [periodicity, setPeriodicity] = useState('');
  const [name, setName] = useState('');
  const [emails, setEmails] = useState('');

  const [systemValue, setSystemValue] = useState([]);
  const [isSystemTouched, setSystemTouched] = useState(false);

  const onChangeDate = useCallback((date) => {
    setStart(getMomentObject(date));
  }, []);

  useEffect(() => {
    setStart(getMomentObject(shedule[SHEDULE_START_TIME]));
  }, [shedule]);

  const systemsList = systems.map((system) => ({
    content: system.text,
    value: system,
  }));

  useEffect(() => {
    if (shedule[SHEDULE_CRONTAB]) {
      setPeriodicity(getPeriodFromCrontab(shedule[SHEDULE_CRONTAB]));
    }
  }, [shedule, getPeriodFromCrontab]);

  useEffect(() => {
    setName(shedule[SHEDULE_NAME]);
  }, [shedule]);

  useEffect(() => {
    if (shedule[SHEDULE_EMAILS]) {
      setEmails(shedule[SHEDULE_EMAILS].join(', '));
    }
  }, [shedule]);

  const handleCreate = useCallback(() => {
    const choosedSystems =
      systemValue.length > 0
        ? systemValue.map((system) => system.key)
        : systems
            .filter((system) =>
              shedule[SHEDULE_SYSTEMS].some((s) => s === system.text),
            )
            .map((s) => s.key);

    const { crontab, period } = createPeriodAndCrontab(periodicity, start);
    const startTime = start.format('YYYY-MM-DDTHH:mm');
    const emailsArray = emails.split(', ').map((email) => email.trim());

    const data = {
      systems: choosedSystems,
      emails: emailsArray,
      start_time: startTime,
      period,
      crontab,
    };

    if (name !== shedule[SHEDULE_NAME]) {
      data.name = name;
    }

    editReport(data, shedule[SHEDULE_ID]).then((result) => {
      if (result.error) {
        onError(result.payload.message);
      } else {
        onOk(name);
        firstFetch();
      }
    });
  }, [
    createPeriodAndCrontab,
    editReport,
    emails,
    name,
    onError,
    firstFetch,
    onOk,
    start,
    shedule,
    systemValue,
    systems,
    periodicity,
  ]);

  const onOk = useCallback(
    (reportName) => {
      onModalCancel();
      bizoneNotification.success({
        message: `${I18n.t(
          'createReport.createConfirm.settings',
        )}${reportName} ${I18n.t('createReport.createConfirm.saved')}`,
      });
    },
    [onModalCancel],
  );

  const onError = useCallback((error) => {
    notification.error({ message: error });
  }, []);

  const onModalCancel = useCallback(() => {
    onCancel();
    setSystemValue([]);
    setSystemTouched(false);
  }, [onCancel]);

  const periodsList = [
    { content: I18n.t('createReport.periodicity.everyDay'), value: 'everyDay' },
    {
      content: I18n.t('createReport.periodicity.everyWeek'),
      value: 'everyWeek',
    },
    {
      content: I18n.t('createReport.periodicity.twiceAMonth'),
      value: 'twiceAMonth',
    },
    {
      content: I18n.t('createReport.periodicity.onceAMonth'),
      value: 'onceAMonth',
    },
  ];

  const initialValues = {
    systemList: systemValue,
    reportName: name,
    periodicity,
    emails,
  };

  return (
    <Form
      initialValues={initialValues}
      cls="createReport"
      validate={({ emails, reportName, systemList }) => {
        if (
          !emails ||
          !emails
            .split(',')
            .map((email) => email.trim())
            .every(validateEmail)
        ) {
          return { emailsError: 'Ibvalid emails' };
        }
        if (systemList.length === 0 && isSystemTouched) {
          return { systemError: 'Empty error' };
        }
        if (!reportName) {
          return { nameError: 'Empty name' };
        }
      }}
    >
      {(form) => {
        const {
          hasSubmitErrors,
          hasValidationErrors,
          submitErrors,
          validating,
          values,
        } = form;
        const isInvalid =
          hasValidationErrors ||
          (hasSubmitErrors &&
            !Object.keys(submitErrors).every(
              (key) => form.form.getFieldState(key).dirtySinceLastSubmit,
            ));
        const frequency = I18n.t(`createReport.info.${values.periodicity}`);
        return (
          <Modal
            title={
              <Header size={20}>{I18n.t('sheduleCard.editReport')}</Header>
            }
            visible={isOpen}
            onCancel={onModalCancel}
            className="CreateReportModal"
            closeIcon={
              <div className="Modal-CloseIcon">
                <Icon glyph="close" size={24} />
              </div>
            }
            footer={[
              <FocusButton
                key="submit"
                rich
                primary
                disabled={validating || isInvalid}
                loading={isLoading}
                onClick={handleCreate}
              >
                <Translate value="createReport.save" />
              </FocusButton>,
              <Button key="back" onClick={onModalCancel}>
                <Translate value="createReport.cancelButton" />
              </Button>,
            ]}
            width={720}
            bodyStyle={{
              padding: '32px 56px 45px 56px',
            }}
          >
            <React.Fragment>
              <Form.Item
                required
                requiredTitle={I18n.t('createReport.requiredTitles.reportName')}
                label={I18n.t('createReport.reportname')}
                name="reportName"
                onChange={(value) => setName(value)}
              >
                <Input disabled={isLoading} />
              </Form.Item>
              <Form.Item
                label={I18n.t('createReport.system')}
                name="systemList"
                onChange={(value) => {
                  setSystemValue(value);
                  setSystemTouched(true);
                }}
              >
                <Select
                  multiple
                  options={systemsList || []}
                  content={[]}
                  disabled={isLoading}
                  placeholder={I18n.t('createReport.placeholders.system')}
                  defaultValue={
                    isSystemTouched
                      ? systemValue.text
                      : shedule[SHEDULE_SYSTEMS]
                  }
                />
              </Form.Item>
              <div className="Periodicity">
                <Form.Item
                  required
                  label={I18n.t('createReport.periodicity.label')}
                  name="periodicity"
                  onChange={(value) => setPeriodicity(value)}
                >
                  <Select
                    options={periodsList}
                    disabled={isLoading}
                    placeholder={I18n.t(
                      'createReport.placeholders.periodicity',
                    )}
                  />
                </Form.Item>
                <Flex direction="column" className="DatePickerItem">
                  <Text className="DatePickerItem-Label">
                    {I18n.t('createReport.periodicity.startLabel')}
                  </Text>
                  <DateContainer>
                    <Datepicker
                      testId="Report_Edit_Datepicker"
                      dropdownProps={dropdownProps}
                      isTimepickerEnabled
                      size="medium"
                      onChange={onChangeDate}
                      value={start}
                    />
                  </DateContainer>
                </Flex>
              </div>
              {start && (
                <>
                  <p className="Info">
                    <Icon glyph="error" size={32} />
                    <Text className="Text">
                      {I18n.t('createReport.info.reports')}
                      {getDateInFullDateAndTimeFormat(
                        locale,
                        start.toString(),
                      )}{' '}
                      {frequency}. {I18n.t('createReport.info.clarification')}
                    </Text>
                  </p>
                  <div>
                    <Form.Item
                      label={I18n.t('createReport.email')}
                      name="emails"
                      requiredTitle={I18n.t(
                        'createReport.requiredTitles.emails',
                      )}
                      onChange={(value) => setEmails(value)}
                    >
                      <Textarea
                        disabled={isLoading}
                        placeholder={I18n.t('createReport.placeholders.email')}
                      />
                    </Form.Item>
                  </div>
                </>
              )}
            </React.Fragment>
          </Modal>
        );
      }}
    </Form>
  );
};
