import { connect } from 'react-redux';

import { FilterPanel } from '@common/soc-react-kit';

import { INCIDENTS_ENTITY } from '../services/api';
import {
  currentLocale,
  getFiltersSettings,
  getFiltersTagText,
  getMitreTechniques,
  getSelectedFilters,
  makeIsShowFiltersSelector,
} from '../store/reducers';
import { setFilter } from '../store/reducers/filters/actions';

function makeMapStateToProps(state, ownProps) {
  const { dispatch = (props) => props, entity = INCIDENTS_ENTITY } = ownProps;
  const isShowPanelSelector = makeIsShowFiltersSelector(entity);

  return (state) => ({
    isShow: isShowPanelSelector(state),
    isLoading: state.filters.isLoading,
    currentLocale: currentLocale(state),
    filters: getSelectedFilters(entity, state),
    settings: getFiltersSettings(entity, state, dispatch),
    filterTagText: getFiltersTagText(entity, state),
    techniques: getMitreTechniques(state),
  });
}

function mapDispatchToProps(dispatch, ownProps) {
  const { entity = INCIDENTS_ENTITY } = ownProps;
  return {
    setFilter: (key, item) =>
      dispatch(setFilter(entity, key, item, ownProps.updateFunction)),
  };
}
const filterPanelContainer = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)(FilterPanel);
export default filterPanelContainer;
