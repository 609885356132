import { normalize } from 'normalizr';
import { createAction } from 'redux-api-middleware';

import { getMitreIsLoading, getMitreMapping, withHeaders } from '..';
import { MITRE_MATRIX_ENDPOINT } from '../../../services/api';

import * as types from './actionTypes';
import { responseSchema } from './reducer';

export const fetchMitreMapping = () =>
  createAction({
    endpoint: MITRE_MATRIX_ENDPOINT,
    method: 'GET',
    headers: withHeaders({ 'Content-Type': 'application/json' }),
    bailout: (state) =>
      Object.keys(getMitreMapping(state)).length !== 0 ||
      getMitreIsLoading(state),
    types: [
      types.FETCH_MAPPING_REQUEST,
      types.FETCH_MAPPING_SUCCESS,
      types.FETCH_MAPPING_FAILURE,
    ],
  });

export const getPanelMitre = (dispatch, getState) => {
  return dispatch(
    createAction({
      endpoint: MITRE_MATRIX_ENDPOINT,
      method: 'GET',
      headers: withHeaders({ 'Content-Type': 'application/json' }),
      types: [types.GET_REQUEST, types.GET_SUCCESS, types.GET_FAILURE],
      isAbortOldRequests: true,
    }),
  ).then((action) => {
    if (action.type !== types.GET_SUCCESS) {
      return {
        tactics: {},
        techniques: {},
      };
    }

    const normalized = normalize(action.payload, responseSchema);

    const mapping = Object.fromEntries(
      normalized.result.tactics.map((key) => [key, []]),
    );

    normalized.result.techniques.forEach((techniqueId) => {
      normalized.entities.techniques[techniqueId].tactics.forEach((tactic) => {
        mapping[tactic].push(techniqueId);
      });
    });

    return {
      tactics: normalized.entities.tactics,
      techniques: normalized.entities.techniques,
    };
  });
};
