import React, { FC } from 'react';
import { I18n } from 'react-redux-i18n';

import {
  Button,
  Dropdown,
  Flex,
  IconButton,
  Node,
  Tooltip,
} from 'combinezone/core/index';
import { AngleDown, AngleUp, SortAsc, SortDesc } from 'combinezone/icons/index';

import {
  SortProps,
  OrderingEntriesType,
  SortTypes,
  SortListType,
} from '../types';

const Sort: FC<SortProps> = ({ isLoading, ordering, setSort, sortList }) => {
  const [orderType = 'created', order = 'DESC'] = (
    Object.entries(ordering) as [OrderingEntriesType]
  )[0];

  const onSelectOrder = ({ order }: { order: keyof SortTypes }) => {
    setSort({ [orderType]: order });
  };

  const onSelectType = ({ type }: { type: keyof SortListType }) => {
    setSort({ [type]: order });
  };

  const dropdownContent = ({ onClose }: { onClose: () => void }) => (
    <div className="Dropdown-List">
      {(Object.keys(sortList) as [keyof SortListType]).map((type) => (
        <Node
          testId={`Node_SortList_${type}`}
          accent={orderType === type ? 'active' : undefined}
          onClick={() => {
            orderType !== type && onSelectType({ type });
            onClose();
          }}
        >
          {I18n.t(`incidentList.sort.${type}.helpText`)}
        </Node>
      ))}
    </div>
  );

  return (
    <Flex className="Sort">
      <Tooltip content={sortList[orderType][order]} position="top">
        <Button
          className="SortButton"
          testId="SortButton"
          isDisabled={isLoading}
          onClick={() =>
            onSelectOrder({ order: order === 'DESC' ? 'ASC' : 'DESC' })
          }
        >
          {order === 'ASC' ? (
            <SortAsc className="Icon" />
          ) : (
            <SortDesc className="Icon" />
          )}
        </Button>
      </Tooltip>
      <Dropdown
        content={dropdownContent}
        position="bottom-right"
        returnFocusOnClose={false}
      >
        {({ isOpen }) => (
          <IconButton
            testId=""
            className="SortControl"
            isPressed={isOpen}
            icon={isOpen ? AngleUp : AngleDown}
            tooltip={{
              content: I18n.t(`filterPanel.sortControl`),
              position: 'top',
            }}
          />
        )}
      </Dropdown>
    </Flex>
  );
};

export default Sort;
