import { createAction } from 'redux-api-middleware';

import {
  incCardAlertsListNexPage,
  incCardSelectedAlert,
  withHeaders,
  incPageCmdbAssetsNext,
} from '..';
import {
  KEY_FIELD,
  INCIDENTS_ENDPOINT,
  fetchAllPages,
} from '../../../services/api';
import { NCCCIB } from '../../../utils/brending';
import { getFormBodyFromJson } from '../../../utils/helpers';

import * as types from './actionTypes';

export const fetchIncCard = (incNumber) =>
  createAction({
    endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/`,
    method: 'GET',
    headers: withHeaders({ 'Content-Type': 'application/json' }),
    types: [
      types.FETCH_INC_CARD_REQUEST,
      types.FETCH_INC_CARD_SUCCESS,
      types.FETCH_INC_CARD_FAIL,
    ],
  });

export const updateIncCard = (incNumber, body) => {
  return createAction({
    endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/`,
    method: 'PATCH',
    headers: withHeaders({ 'Content-Type': 'application/json' }),
    types: [
      types.UPDATE_INC_CARD_REQUEST,
      types.UPDATE_INC_CARD_SUCCESS,
      types.UPDATE_INC_CARD_FAIL,
    ],
    body: JSON.stringify(body),
  });
};

export const sendFeedback = (body) => {
  return (dispatch, getState) => {
    const incNumber = getState().incCard.incident[KEY_FIELD];

    const action = createAction({
      endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/add_rating/`,
      method: 'POST',
      body: JSON.stringify(body),
      headers: withHeaders({ 'Content-Type': 'application/json' }),
      types: [
        {
          type: types.ADD_FEEDBACK_REQUEST,
          meta: { incNumber, body },
        },
        {
          type: types.ADD_FEEDBACK_SUCCESS,
          meta: { incNumber, body },
        },
        {
          type: types.ADD_FEEDBACK_FAILURE,
          meta: { incNumber, body },
        },
      ],
    });

    return dispatch(action);
  };
};

export const fetchIncComments = (incNumber) =>
  createAction({
    endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/comments/`,
    method: 'GET',
    headers: withHeaders({ 'Content-Type': 'application/json' }),
    types: [
      types.FETCH_COMMENTS_REQUEST,
      types.FETCH_COMMENTS_SUCCESS,
      types.FETCH_COMMENTS_FAILURE,
    ],
    fetch: fetchAllPages,
  });

export const fetchAlerts = (incNumber) =>
  createAction({
    endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/alerts/`,
    method: 'GET',
    headers: withHeaders({ 'Content-Type': 'application/json' }),
    types: [
      types.FETCH_INC_CARD_ALERTS_REQUEST,
      types.FETCH_INC_CARD_ALERTS_SUCCESS,
      types.FETCH_INC_CARD_ALERTS_FAIL,
    ],
    fetch: fetchAllPages,
  });

export const fetchSelectedAlert = (incNumber) => (dispatch, getState) =>
  dispatch(
    createAction({
      endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/alerts/${
        incCardSelectedAlert(getState()).uuid
      }/`,
      method: 'GET',
      headers: withHeaders({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_INC_CARD_SELECTED_ALERT_REQUEST,
        types.FETCH_INC_CARD_SELECTED_ALERT_SUCCESS,
        types.FETCH_INC_CARD_SELECTED_ALERT_FAIL,
      ],
    }),
  );

export const fetchSelectedAlertGroupedData =
  (incNumber) => (dispatch, getState) =>
    dispatch(
      createAction({
        endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/alerts/${
          incCardSelectedAlert(getState()).uuid
        }/grouped_alert_data/`,
        method: 'GET',
        headers: withHeaders({ 'Content-Type': 'application/json' }),
        types: [
          types.FETCH_INC_CARD_SELECTED_ALERT_GROUPED_DATA_REQUEST,
          types.FETCH_INC_CARD_SELECTED_ALERT_GROUPED_DATA_SUCCESS,
          {
            type: types.FETCH_INC_CARD_SELECTED_ALERT_GROUPED_DATA_FAIL,
            meta: () => ({
              showNotification: false,
            }),
          },
        ],
      }),
    );

export const fetchSelectedAlertTree = (incNumber) => (dispatch, getState) =>
  dispatch(
    createAction({
      endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/alerts/${
        incCardSelectedAlert(getState()).uuid
      }/tree/`,
      method: 'GET',
      headers: withHeaders({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_INC_CARD_SELECTED_ALERT_TREE_REQUEST,
        types.FETCH_INC_CARD_SELECTED_ALERT_TREE_SUCCESS,
        {
          type: types.FETCH_INC_CARD_SELECTED_ALERT_TREE_FAIL,
          meta: () => ({
            showNotification: false,
          }),
        },
      ],
    }),
  );

export const fetchSelectedAlertSymptomatic =
  (incNumber) => (dispatch, getState) =>
    dispatch(
      createAction({
        endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/alerts/${
          incCardSelectedAlert(getState()).uuid
        }/symptomatic/`,
        method: 'GET',
        headers: withHeaders({ 'Content-Type': 'application/json' }),
        types: [
          types.FETCH_INC_CARD_SELECTED_ALERT_SYMPTOMATIC_REQUEST,
          types.FETCH_INC_CARD_SELECTED_ALERT_SYMPTOMATIC_SUCCESS,
          {
            type: types.FETCH_INC_CARD_SELECTED_ALERT_SYMPTOMATIC_FAIL,
            meta: () => ({
              showNotification: false,
            }),
          },
        ],
      }),
    );

export const selectAlert = (alert) => ({
  type: types.INC_CARD_SELECT_ALERT,
  payload: alert,
});

export const setSearch = (search) => ({
  type: types.INC_CARD_SET_ALERT_SEARCH,
  payload: search,
});

export const submitComment = (incNumber, body) => {
  const isCompleted = body.issue_completed;
  const formData = getFormBodyFromJson({
    body: body.body,
    issue_completed: body.issue_completed,
    attachments: body.attachments,
  });

  return createAction({
    endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/comments/`,
    method: 'POST',
    body: formData,
    headers: withHeaders({}),
    types: [
      {
        type: types.ADD_COMMENT_REQUEST,
        meta: { incNumber },
      },
      {
        type: types.ADD_COMMENT_SUCCESS,
        meta: { incNumber, isCompleted },
      },
      {
        type: types.ADD_COMMENT_FAIL,
        meta: { incNumber },
      },
    ],
  });
};

export const sendToGossopka = (incNumber) =>
  createAction({
    endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/manually_send_to_${
      NCCCIB ? 'gti' : 'gossopka'
    }/`,
    method: 'POST',
    headers: withHeaders({ 'Content-Type': 'application/json' }),
    types: [
      types.SEND_GOSSOPKA_REQUEST,
      types.SEND_GOSSOPKA_SUCCESS,
      types.SEND_GOSSOPKA_FAILURE,
    ],
  });

export const closeIncCard = () => ({
  type: types.CLOSE_INC_CARD,
});

export const finalizeCommentSuccess = () => ({
  type: types.ADD_COMMENT_MODAL_SUCCESS,
});

export const fetchIncCardAlertsFirstPage = (incNumber) =>
  createAction({
    endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/alerts/?page=1`,
    method: 'GET',
    headers: withHeaders({ 'Content-Type': 'application/json' }),
    types: [
      types.FETCH_INC_CARD_ALERTS_FIRST_PAGE_REQUEST,
      types.FETCH_INC_CARD_ALERTS_FIRST_PAGE_SUCCESS,
      types.FETCH_INC_CARD_ALERTS_FIRST_PAGE_FAIL,
    ],
  });

export const fetchIncCardAlertsMore = (incNumber) => (dispatch, getState) => {
  const state = getState();
  const page = incCardAlertsListNexPage(state);
  const { lastFetchTime } = state.incCard.alerts;

  const action = createAction({
    endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/alerts/?page=${page}`,
    method: 'GET',
    headers: withHeaders({ 'Content-Type': 'application/json' }),
    types: [
      types.FETCH_INC_CARD_ALERTS_MORE_PAGE_REQUEST,
      {
        type: types.FETCH_INC_CARD_ALERTS_MORE_PAGE_SUCCESS,
        meta: { timestamp: lastFetchTime },
      },
      {
        type: types.FETCH_INC_CARD_ALERTS_MORE_PAGE_FAIL,
        meta: { showNotification: false },
      },
    ],
  });

  return dispatch(action);
};

export const fetchCmdbAssetIncList = (incNumber) => (dispatch) => {
  const action = createAction({
    endpoint: `${INCIDENTS_ENDPOINT}${incNumber}/snapshots/`,
    method: 'GET',
    headers: withHeaders({ 'Content-Type': 'application/json' }),
    types: [
      types.FETCH_INC_CARD_CMDB_ASSETS_REQUEST,
      types.FETCH_INC_CARD_CMDB_ASSETS_SUCCESS,
      types.FETCH_INC_CARD_CMDB_ASSETS_FAIL,
    ],
  });

  return dispatch(action);
};

export const fetchMoreCmdbAssetIncList = (dispatch, getState) => {
  const next = incPageCmdbAssetsNext(getState());
  if (next) {
    const action = createAction({
      endpoint: next,
      method: 'GET',
      headers: withHeaders({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_INC_CARD_MORE_CMDB_ASSETS_REQUEST,
        types.FETCH_INC_CARD_MORE_CMDB_ASSETS_SUCCESS,
        types.FETCH_INC_CARD_MORE_CMDB_ASSETS_FAIL,
      ],
    });
    return dispatch(action);
  }
};
