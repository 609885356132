import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Redirect } from 'react-router-dom';

import { Block } from '@bizone/ui-bundle/esm/Block';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { Label } from '@bizone/ui-bundle/esm/Label';
import { Subheader } from '@bizone/ui-bundle/esm/Subheader';
import { Tooltip } from '@bizone/ui-bundle/esm/Tooltip';

import { Layout } from '@common/soc-react-kit';
import { Entity } from '@soc/alerts/src';
import RelationsElement from '@soc/issues/src/IssueCard/components/IssueCardTabs/components/RelationsElement';
import { Tabs } from 'antd';
import { Text, Flex } from 'combinezone/core';

import Panel from '../../containers/IncidentPage-PanelContainer';
import {
  AFFECTED_SYSTEMS,
  DESCRIPTION_FIELD,
  RESOLUTION_DETAILED_FIELD,
  GOSSOPKA_SEND_TIME,
  SHOW_GOSSOPKA_BUTTON,
  RECOMMENDATIONS_FIELD,
  OBSERVED_IOCS,
} from '../../services/api';
import Markdown from '../Markdown';
import notification from '../Notification';
import ReadMore from '../ReadMore';

import IOCTable from './IOCSTable';
import NCCCI from './NCCCI';
import ReadMoreMarkdown from './ReadMore';

import './IncidentPage.scss';
import IncidentPageRightPanelContainer from '../../containers/incidentPage-RightPanelContainer';

import CmdbAssetsTab from './CmdbAssetTab';
import { AlertsBlock } from './AlertsBock';

import { MisconfigsTab } from '@soc/misconfigs/src';

const { TabPane } = Tabs;

export class IncidentPage extends React.Component {
  state = {
    addIsOpen: false,
    heightOneDescription: 0,
  };

  componentDidMount() {
    const { fetchFirstPage, fetchMitreMapping, incNumber } = this.props;

    this.fetchIncident();
    fetchFirstPage(incNumber);
    fetchMitreMapping();
  }

  componentWillUnmount() {
    this.props.closeIncCard();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.incident !== this.props.incident) {
      const windowInnerHeight = window.innerHeight;
      let numberDescription = 1;
      if (this.props.incident[RESOLUTION_DETAILED_FIELD]) {
        numberDescription += 1;
      }
      if (this.props.incident[RECOMMENDATIONS_FIELD]) {
        numberDescription += 1;
      }
      this.setState({
        heightOneDescription: (windowInnerHeight - 500) / numberDescription,
      });
    }
  }

  fetchIncident = () => {
    const { fetchAlerts, fetchIncCard, fetchIncComments, incNumber } =
      this.props;

    fetchIncCard(incNumber);
    fetchIncComments(incNumber);
  };

  sendToGossopka = () => {
    const { incNumber, sendToGossopka } = this.props;

    sendToGossopka(incNumber).then((response) => {
      if (!response.error) {
        notification.success({ message: I18n.t('incCard.NCCCI.sendSuccess') });
        this.fetchIncident();
      }
    });
  };

  render() {
    const {
      alertsTotalCount,
      fetchError,
      gossopkaLoading,
      incNumber,
      incident,
      locale,
    } = this.props;
    const {
      [GOSSOPKA_SEND_TIME]: sendToGossopkaTime,
      [OBSERVED_IOCS]: iocs,
      [RECOMMENDATIONS_FIELD]: recommendations,
      [SHOW_GOSSOPKA_BUTTON]: showGossopkaButton = false,
    } = incident;

    const hasSolution = !!incident[RESOLUTION_DETAILED_FIELD];
    const hasAssets =
      incident[AFFECTED_SYSTEMS] && incident[AFFECTED_SYSTEMS].length > 0;
    const hasIocs = iocs && iocs.length > 0;

    if (fetchError) {
      return <Redirect to="/incidents" />;
    }

    return (
      <Layout.Container id="IncCardContainer" className="inc-card">
        <Layout.Content className="inc-card-content">
          <Panel
            className="inc-card-left-block"
            incident={incident}
            sendToGossopkaTime={sendToGossopkaTime}
            updateFunction={this.fetchIncident}
          />
          <div className="inc-card-center-block">
            <Tabs
              tabBarExtraContent={
                showGossopkaButton ? (
                  <NCCCI
                    plannedSendTime={sendToGossopkaTime}
                    isSending={gossopkaLoading}
                    onClick={this.sendToGossopka}
                    updateFunction={this.fetchIncident}
                  />
                ) : undefined
              }
              onChange={this.onChangeTab}
            >
              <TabPane
                tab={
                  <Text as="span" isClipped>
                    <Translate value="incCard.header.details" />
                  </Text>
                }
                key="incident"
              >
                <section
                  id="inc-details"
                  className="inc-card-info inc-card-details"
                >
                  <Block
                    collapsible
                    separateHeader
                    expandable
                    title={
                      <Header size={16}>
                        <Translate
                          value={
                            hasSolution ? 'incCard.faqSolution' : 'incCard.faq'
                          }
                        />
                      </Header>
                    }
                  >
                    <div className="inc-card-description">
                      <Subheader size={14} row>
                        {I18n.t('incCard.description')}
                      </Subheader>
                      <ReadMoreMarkdown
                        maxHeight={this.state.heightOneDescription}
                        more={I18n.t('incCard.more')}
                        less={I18n.t('incCard.less')}
                      >
                        <Markdown value={incident[DESCRIPTION_FIELD] || ''} />
                      </ReadMoreMarkdown>

                      {hasSolution && (
                        <div style={{ marginTop: 32 }}>
                          <Subheader size={14} row>
                            {I18n.t('incCard.resolution')}
                          </Subheader>
                          <ReadMoreMarkdown
                            maxHeight={this.state.heightOneDescription}
                            more={I18n.t('incCard.more')}
                            less={I18n.t('incCard.less')}
                          >
                            <Markdown
                              value={incident[RESOLUTION_DETAILED_FIELD] || ''}
                            />
                          </ReadMoreMarkdown>
                        </div>
                      )}

                      {recommendations && (
                        <div style={{ marginTop: 32 }}>
                          <Subheader size={14} row>
                            {I18n.t('incCard.recommendations')}
                          </Subheader>
                          <ReadMore
                            lines={Math.max(
                              4,
                              Math.floor(this.state.heightOneDescription / 17),
                            )}
                            more={I18n.t('incCard.more')}
                            less={I18n.t('incCard.less')}
                          >
                            {recommendations || ''}
                          </ReadMore>
                        </div>
                      )}
                    </div>
                  </Block>
                </section>
              </TabPane>
              <TabPane
                tab={
                  <div>
                    <Text as="span">
                      <Translate value="incCard.header.cmdbAssets" />
                    </Text>
                  </div>
                }
                key="cmdbAssets"
              >
                <section
                  id="cmdbAssets"
                  className="inc-card-info inc-card-assets"
                >
                  <CmdbAssetsTab incNumber={incNumber} />
                </section>
              </TabPane>
              <TabPane
                tab={
                  alertsTotalCount > 0 ? (
                    <Flex>
                      <Text as="span" isClipped>
                        <Translate value="incCard.header.alerts" />
                      </Text>
                      <Label offsetLeft={8}>{alertsTotalCount}</Label>
                    </Flex>
                  ) : (
                    <Tooltip position="top" inverted content="Нет данных">
                      <span>
                        <Translate value="incCard.header.alerts" />
                      </span>
                    </Tooltip>
                  )
                }
                key="alerts"
                disabled={alertsTotalCount === 0}
              >
                {alertsTotalCount > 0 && (
                  <section
                    id="alerts"
                    className="inc-card-info inc-card-alerts"
                  >
                    <AlertsBlock
                      alertsCount={alertsTotalCount}
                      incKey={incNumber}
                    />
                  </section>
                )}
              </TabPane>
              <TabPane
                tab={
                  hasIocs ? (
                    <div>
                      <Text as="span">
                        <Translate value="incCard.header.identifiedIocs" />
                      </Text>
                      <Label offsetLeft={8}>{iocs.length}</Label>
                    </div>
                  ) : (
                    <Tooltip position="top" inverted content="Нет данных">
                      <span>
                        <Translate value="incCard.header.identifiedIocs" />
                      </span>
                    </Tooltip>
                  )
                }
                key="iocs"
                disabled={!hasIocs}
              >
                {hasIocs && (
                  <section id="iocs" className="inc-card-info inc-card-iocs">
                    <Block
                      collapsible
                      separateHeader
                      expandable
                      title={
                        <Header size={16}>
                          <Translate value="incCard.iocs.title" />
                          <Label offsetLeft={8}>{iocs.length}</Label>
                        </Header>
                      }
                    >
                      <IOCTable data={iocs} locale={locale} />
                    </Block>
                  </section>
                )}
              </TabPane>
              {incident?.issuetype === 'Remediation' && (
                <TabPane
                  tab={
                    <div>
                      <Text as="span" isClipped>
                        <Translate value="incCard.header.misconfigs" />
                      </Text>
                    </div>
                  }
                  key="misconfigs"
                >
                  <section
                    id="misconfigs"
                    className="inc-card-info inc-card-assets"
                  >
                    <MisconfigsTab
                      endpoint={`/soc/incs/${incNumber}/misconfigs/`}
                      entity={Entity.MISCONFIGS_SOC_INCIDENT}
                      testId="incident-page-misconfigs-tab"
                    />
                  </section>
                </TabPane>
              )}
              <TabPane
                tab={<div>{I18n.t('incCard.header.relations')}</div>}
                key="relation"
              >
                <section id="relation" className="relation-tab">
                  <RelationsElement
                    testId="incindent-page"
                    systemId={incident.system}
                    isBaseIssueContext={false}
                  />
                </section>
              </TabPane>
            </Tabs>
          </div>
          <IncidentPageRightPanelContainer
            incNumber={incNumber}
            className="inc-card-right-block"
          />
        </Layout.Content>
      </Layout.Container>
    );
  }
}

IncidentPage.defaultProps = {
  incident: {},
  comments: [],
  fetchErrors: false,
  gossopkaLoading: false,
};
