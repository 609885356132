import React, { Component } from 'react';

import './AmChart.scss';
import isEqual from 'react-fast-compare';
import { Translate } from 'react-redux-i18n';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4langRuRU from '@amcharts/amcharts4/lang/ru_RU';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import { getMomentObject } from '@common/soc-react-kit/src/utils/momentHelpers';

import GeneralBlock from './GeneralBlock';

am4core.useTheme(am4themesAnimated);
am4core.options.commercialLicense = true;

export default class AmChart extends Component {
  componentDidMount() {
    if (this.props.data.length !== 0) {
      this.createAmChart();
    }
  }

  createAmChart() {
    const {
      currentLocale,
      data = [],
      id,
      isChartFontsLoaded = false,
      type,
      widgetSettings,
    } = this.props;

    const amchartChart =
      type === 'pie'
        ? am4charts.PieChart
        : type === 'bar' ||
          type === 'horizontalBar' ||
          type === 'line' ||
          type === 'column'
        ? am4charts.XYChart
        : type === 'gauge'
        ? am4charts.GaugeChart
        : null;

    if (!amchartChart) {
      return;
    }

    const chart = am4core.createFromConfig(
      { ...widgetSettings.config },
      id,
      amchartChart,
    );
    this.chart = chart;

    if (isChartFontsLoaded) {
      chart.fontFamily = 'Roboto';
      chart.fontSize = 12;
      chart.fontWeight = 400;
    } else {
      chart.fontSize = 13;
    }

    chart.data = data;

    if (currentLocale === 'ru') {
      chart.language.locale = am4langRuRU;
    }

    if (widgetSettings.applyToChart) {
      widgetSettings.applyToChart(chart);
    }

    let chartSeries = null;
    if (type === 'pie') {
      chartSeries = 'slices';
    } else if (type === 'bar' || type === 'horizontalBar') {
      chartSeries = 'columns';
    }

    if (chartSeries) {
      chart.series.each((series) => {
        series[chartSeries].template.events.on(
          'hit',
          (ev) => {
            const {
              dataContext: { filterValue },
            } = ev.target.dataItem;
            this.props.setFilter(
              widgetSettings.filterField || widgetSettings.field,
              [filterValue],
            );
          },
          this,
        );
      });
    }

    if (type === 'line') {
      const series = chart.series.getIndex(0);

      series.bullets.each((bullet) => {
        if (bullet._className === 'CircleBullet') {
          bullet.events.on('hit', (ev) => {
            const { dataContext } = ev.target.dataItem;
            this.props.setFilter(
              widgetSettings.filterField || widgetSettings.field,
              [
                getMomentObject(dataContext.date),
                getMomentObject(dataContext.date),
              ],
            );
          });
        }
      });
    }

    if (type === 'bar' || type === 'horizontalBar') {
      const categoryAxis =
        type === 'bar' ? chart.xAxes.getIndex(0) : chart.yAxes.getIndex(0);
      categoryAxis.renderer.labels.template.events.on('hit', (ev) => {
        const {
          dataContext: { filterValue },
        } = ev.target.dataItem;
        this.props.setFilter(
          widgetSettings.filterField || widgetSettings.field,
          [filterValue],
        );
      });
    }

    if (type === 'gauge') {
      const axis = chart.xAxes.getIndex(0);
      const range0 = axis.axisRanges.getIndex(0);
      const range1 = axis.axisRanges.getIndex(1);

      range0.axisFill.events.on('hit', (ev) => {
        this.props.setFilter(
          widgetSettings.filterField || widgetSettings.field,
          ['false'],
        );
      });

      range1.axisFill.events.on('hit', (ev) => {
        this.props.setFilter(
          widgetSettings.filterField || widgetSettings.field,
          ['true'],
        );
      });
    }
  }

  componentDidUpdate(oldProps) {
    const oldType = oldProps.type;
    const oldLocale = oldProps.currentLocale;
    const { currentLocale, data = [], type } = this.props;
    if (this.chart) {
      if (oldType !== type || oldLocale !== currentLocale) {
        this.chart.dispose();
        this.createAmChart();
      }

      if (
        oldProps.isChartFontsLoaded === false &&
        this.props.isChartFontsLoaded === true
      ) {
        this.chart.fontFamily = 'Roboto';
        this.chart.fontSize = 12;
        this.chart.fontWeight = 400;
      }

      const oldData = this.chart.data;

      if (type === 'line') {
        if (
          oldData.length &&
          data.length &&
          oldData[0].date === data[0].date &&
          oldData.slice(-1)[0].date === data.slice(-1)[0].date
        ) {
          // If same daterange
          let isDataUpdated = false;
          this.chart.data.forEach((item, i) => {
            if (item.value !== data[i].value) {
              item.value = data[i].value;
              isDataUpdated = true;
            }
          });

          if (isDataUpdated) {
            this.chart.invalidateRawData();
          }
        } else {
          this.chart.data = data;
        }
      } else if (type === 'column') {
        const countsDict = data.reduce((acc, el) => {
          acc[el.date] = el.value;
          return acc;
        }, {});
        const oldCountsDict = oldData.reduce((acc, el) => {
          acc[el.date] = el.value;
          return acc;
        }, {});

        if (!isEqual(countsDict, oldCountsDict)) {
          this.chart.data = data;
        }
      } else {
        const countsDict = data.reduce((acc, el) => {
          acc[el.category] = el.value;
          return acc;
        }, {});

        const oldCountsDict = oldData.reduce((acc, el) => {
          acc[el.category] = el.value;
          return acc;
        }, {});

        if (!isEqual(countsDict, oldCountsDict)) {
          this.chart.data = data;
        }
      }
    } else if (data.length !== 0) {
      this.createAmChart();
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const {
      data = [],
      id,
      widgetSettings: { generalBlock },
    } = this.props;

    return (
      <React.Fragment>
        <div
          style={{
            flexGrow: 1,
            display: data.length === 0 ? 'none' : 'inline-flex',
            flexDirection: 'column',
          }}
        >
          {generalBlock && (
            <GeneralBlock
              title={generalBlock.title}
              value={generalBlock.getValueFromData(data)}
            />
          )}
          <div
            style={{
              height: '100%',
            }}
            id={id}
          />
        </div>
        {data.length === 0 && (
          <Translate className="noData" value="widget.noData" />
        )}
      </React.Fragment>
    );
  }
}
