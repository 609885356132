import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { compose } from 'redux';

import { Panel } from '../components/IncidentPage/Panel';
import {
  currentLocale,
  getMitreTactics,
  getMitreTechniques,
} from '../store/reducers';
import { downloadFile } from '../store/reducers/fileDownloader/actions';
import { sendFeedback, updateIncCard } from '../store/reducers/incCard/actions';
import withQueryParams from '../utils/withQueryParams';

const mapStateToProps = (state) => ({
  locale: currentLocale(state),
  mitreTechniques: getMitreTechniques(state),
  mitreTactics: getMitreTactics(state),
});

const mapDispatchToProps = {
  updateIncCard,
  sendFeedback,
  downloadFile,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withQueryParams,
  withRouter,
)(Panel);
