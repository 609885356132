import React, { useState } from 'react';

import { Text } from 'combinezone/core';
import { TreeFile, Delete, FilePdf, FileTxt } from 'combinezone/icons';

import './index.scss';
import { ChatFilePropsType } from '../types';

const mimeTypeIcon: { [key: string]: React.ReactElement } = {
  'application/pdf': <FilePdf />,
  'text/html': <FileTxt />,
  'text/plain': <FileTxt />,
};

const getFileNameAndExt = (name: string) => {
  const fileName = name.split('.');
  const fileExt = fileName.pop() || '';
  return [fileName.join(''), fileExt];
};

export const ChatFile = ({
  file,
  internalId,
  removeFile,
  thumbnailUrl,
}: ChatFilePropsType) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseOver = () => {
    setIsMouseOver(true);
  };
  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const [name, ext] = getFileNameAndExt(file.name);

  return (
    <div className="ChatFile">
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <div
        className="ChatFile-Box"
        onMouseOver={handleMouseOver}
        onMouseEnter={handleMouseOver}
      >
        {isMouseOver && (
          <div
            className="ChatFile-FileDeleteOverlay"
            onMouseLeave={handleMouseLeave}
            onClick={() => removeFile(internalId)}
          >
            <Delete color="#E8594F" />
          </div>
        )}
        {thumbnailUrl ? (
          <img
            className="ChatFile-Preview"
            alt={file.name}
            src={thumbnailUrl}
          />
        ) : (
          <div className="ChatFile-IconWrapper">
            {mimeTypeIcon[file.type] ? mimeTypeIcon[file.type] : <TreeFile />}
          </div>
        )}
      </div>
      <div className="ChatFile-Title">
        <Text className="ChatFile-Name" variant="secondary">
          {name}
        </Text>
        <Text className="ChatFile-Ext" variant="secondary">
          .{ext}
        </Text>
      </div>
    </div>
  );
};
