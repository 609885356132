import { api, SOC_URL } from '@soc/kit/api';

import { statusActionsFetchAbortControllerKey } from '../constants';
import { StatusActionsFetch, StatusActionsResults } from '../types';

export const fetchStatusActions: StatusActionsFetch = ({ incidentId }) => {
  return api<StatusActionsResults>(
    `${SOC_URL}incs/${incidentId}/available_actions/`,
    {
      method: 'get',
      abortControllerKey: statusActionsFetchAbortControllerKey,
    },
  );
};
