import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import '@common/soc-react-kit/src/utils/momentHelpers';

import 'react-grid-layout/css/styles.css';
import '@bizone/ui-bundle/esm/bundle.css';
import '@common/soc-react-kit/dist/bundle.css';
import './index.scss';

import App from './containers/App';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import { history } from './utils/history';

import './antd-override.less';
import './bizone-legacy-override.scss';

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <LastLocationProvider>
        <App />
      </LastLocationProvider>
    </Router>
  </Provider>,
  document.getElementById('root'),
);

registerServiceWorker();
