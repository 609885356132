import React from 'react';
import { Translate } from 'react-redux-i18n';

import styled from 'styled-components';
import { getMomentObject } from '@common/soc-react-kit/src/utils/momentHelpers';

import {
  CATEGORY_FIELD,
  CREATE_FIELD,
  PRIORITY_FIELD,
  RESOLUTION_DATE_FIELD,
  STATUS_FIELD,
  SYSTEM_FIELD,
  SLA_FIELD,
  SLA_BREACHED_FIELD,
  GOSSOPKA_ID,
  INSTRUCTIONS_CREATED,
  INSTRUCTIONS_UPDATED,
  SERVICE_FIELD,
  INCIDENTS_ENTITY,
  INSTRUCTIONS_ENTITY,
  PROTECTED_SYSTEMS_ENTITY,
  RESPONSE_INSTRUCTIONS_ENTITY,
  ARTICLES_ENTITY,
  RULES_ENTITY,
  RULES_SEVERITY,
  RULES_CATEGORY,
  RULES_CONFIDENCE,
  RULES_CREATED,
} from '../../../services/api';

import * as types from './actionTypes';

const TranslateWIthCurrentColor = styled(Translate)`
  color: ${({ theme }) => theme.basis.colors.text.default};
`;

const initialState = {
  isLoading: false,
  [INCIDENTS_ENTITY]: {
    isShow: false,
    ordering: { created: 'DESC' },
    selected: {},
    settings: {
      [SYSTEM_FIELD]: {},
      [CATEGORY_FIELD]: {},
      [PRIORITY_FIELD]: {},
      [STATUS_FIELD]: {},
      [CREATE_FIELD]: {},
      [SERVICE_FIELD]: {},
      [RESOLUTION_DATE_FIELD]: {},
      [SLA_FIELD]: {},
      [SLA_BREACHED_FIELD]: {},
      [SLA_BREACHED_FIELD]: {},
    },
    // default range
    dateRange: [
      getMomentObject(new Date()).subtract(6, 'days'),
      getMomentObject(new Date()),
    ],
    search: '',
  },
  [INSTRUCTIONS_ENTITY]: {
    isShow: false,
    ordering: { [INSTRUCTIONS_UPDATED]: 'DESC' },
    selected: {},
    settings: {
      [INSTRUCTIONS_CREATED]: {},
      [INSTRUCTIONS_UPDATED]: {},
    },
    search: '',
  },
  [RESPONSE_INSTRUCTIONS_ENTITY]: {
    selected: {},
    ordering: { [INSTRUCTIONS_UPDATED]: 'DESC' },
    search: '',
  },
  [ARTICLES_ENTITY]: {
    selected: {},
    ordering: { [INSTRUCTIONS_UPDATED]: 'DESC' },
    search: '',
  },
  [PROTECTED_SYSTEMS_ENTITY]: {
    settings: {},
  },
  [RULES_ENTITY]: {
    selected: {},
    ordering: { [RULES_CREATED]: 'DESC' },
    search: '',
  },
};

export default function filtersReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SEARCH_CHANGE: {
      const { entity } = action;
      return {
        ...state,
        [entity]: {
          ...state[entity],
          search: action.payload,
        },
      };
    }

    case types.NEW_DAY_STARTED:
      return {
        ...state,
        incidents: {
          ...state.incidents,
          dateRange: [
            state.incidents.dateRange[0],
            getMomentObject(new Date()),
          ],
        },
        sources: {
          ...state.sources,
          dateRange: [state.sources.dateRange[0], getMomentObject(new Date())],
        },
      };

    case types.SET_FILTER: {
      const {
        entity,
        payload: { filterTagText, items, key },
      } = action;

      const newFilters = { ...state[entity].selected };
      let isNew = state[entity].isShow;

      if (!items || (Array.isArray(items) && items.length === 0)) {
        delete newFilters[key];
      } else {
        newFilters[key] = items;
        isNew = true;
      }

      return {
        ...state,
        [entity]: {
          ...state[entity],
          selected: { ...newFilters },
          isShow: isNew,
          filterTagText: {
            ...state[entity].filterTagText,
            [key]: filterTagText,
          },
        },
      };
    }

    case types.RESET_FILTERS: {
      const { entity } = action;

      return {
        ...state,
        [entity]: {
          ...state[entity],
          isShow: false,
          selected: {},
        },
      };
    }

    case types.SHOW_FILTERS: {
      const { entity } = action;
      return {
        ...state,
        [entity]: {
          ...state[entity],
          isShow: true,
        },
      };
    }

    case types.SET_SORT:
      const { entity } = action;
      return {
        ...state,
        [entity]: {
          ...state[entity],
          ordering: action.meta.ordering,
        },
      };

    case types.FETCH_FILTERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case types.FETCH_FILTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        [INCIDENTS_ENTITY]: {
          ...state[INCIDENTS_ENTITY],
          settings: {
            [SYSTEM_FIELD]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.incidents.types.${SYSTEM_FIELD}`}
                />
              ),
              type: 'multiselect',
              choices: action.payload.systems,
            },
            [CATEGORY_FIELD]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.incidents.types.${CATEGORY_FIELD}`}
                />
              ),
              type: 'multiselect',
              choices: action.payload.categories,
            },
            [PRIORITY_FIELD]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.incidents.types.${PRIORITY_FIELD}`}
                />
              ),
              type: 'multiselect',
              choices: action.payload.priorities,
            },
            [STATUS_FIELD]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.incidents.types.${STATUS_FIELD}`}
                />
              ),
              type: 'multiselect',
              choices: action.payload.statuses,
            },
            [CREATE_FIELD]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.incidents.types.${CREATE_FIELD}`}
                />
              ),
              type: 'date',
            },
            [SERVICE_FIELD]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.incidents.types.${SERVICE_FIELD}`}
                />
              ),
              type: 'multiselect',
              choices: action.payload.base_services,
            },
            [RESOLUTION_DATE_FIELD]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.incidents.types.${RESOLUTION_DATE_FIELD}`}
                />
              ),
              type: 'date',
            },
            [SLA_FIELD]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.incidents.types.${SLA_FIELD}`}
                />
              ),
              type: 'date',
            },
            [SLA_BREACHED_FIELD]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.incidents.types.${SLA_BREACHED_FIELD}`}
                />
              ),
              type: 'multiselect',
              choices: [
                { key: 'true', text: 'Нарушен' },
                { key: 'false', text: 'Не нарушен' },
              ],
            },
            [GOSSOPKA_ID]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.incidents.types.${GOSSOPKA_ID}`}
                />
              ),
              type: 'multiselect',
              choices: [
                { key: 'false', text: 'Отправлен' },
                { key: 'true', text: 'Не отправлен' },
              ],
            },
          },
        },
        [INSTRUCTIONS_ENTITY]: {
          ...state[INSTRUCTIONS_ENTITY],
          settings: {
            [INSTRUCTIONS_CREATED]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.instructions.types.${INSTRUCTIONS_CREATED}`}
                />
              ),
              type: 'date',
            },
            [INSTRUCTIONS_UPDATED]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.instructions.types.${INSTRUCTIONS_UPDATED}`}
                />
              ),
              type: 'date',
            },
          },
        },
        [RULES_ENTITY]: {
          ...state[RULES_ENTITY],
          settings: {
            [RULES_SEVERITY]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.ruleList.types.${RULES_SEVERITY}`}
                />
              ),
              type: 'multiselect',
              choices: action.payload.priorities,
            },
            [RULES_CONFIDENCE]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.ruleList.types.${RULES_CONFIDENCE}`}
                />
              ),
              type: 'multiselect',
              choices: action.payload.priorities,
            },
            [RULES_CATEGORY]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.ruleList.types.${RULES_CATEGORY}`}
                />
              ),
              type: 'multiselect',
              choices: action.payload.categories,
            },
            [RULES_CREATED]: {
              label: (
                <TranslateWIthCurrentColor
                  value={`filterPanel.ruleList.types.${RULES_CREATED}`}
                />
              ),
              type: 'date',
            },
            available_for_service: {
              label: (
                <TranslateWIthCurrentColor value="filterPanel.ruleList.types.availableForService" />
              ),
              type: 'multiselect',
              choices: [
                { key: 'Vision', text: 'Vision' },
                { key: 'Horizon', text: 'Horizon' },
                { key: 'Focus', text: 'Focus' },
                { key: 'Panorama', text: 'Panorama' },
              ],
            },
            is_requested: {
              label: (
                <TranslateWIthCurrentColor value="filterPanel.ruleList.types.isRequested" />
              ),
              type: 'select',
              choices: [
                {
                  key: 'true',
                  text: (
                    <TranslateWIthCurrentColor value="rulePage.isRequestedTypes.true" />
                  ),
                },
                {
                  key: 'false',
                  text: (
                    <TranslateWIthCurrentColor value="rulePage.isRequestedTypes.false" />
                  ),
                },
              ],
            },
            activation_mode: {
              label: (
                <TranslateWIthCurrentColor value="filterPanel.ruleList.types.mode" />
              ),
              type: 'select',
              choices: [
                {
                  key: 'auto',
                  text: (
                    <TranslateWIthCurrentColor value="rulePage.modeTypes.auto" />
                  ),
                },
                {
                  key: 'manual',
                  text: (
                    <TranslateWIthCurrentColor value="rulePage.modeTypes.manual" />
                  ),
                },
              ],
            },
          },
        },
        [PROTECTED_SYSTEMS_ENTITY]: {
          ...state[PROTECTED_SYSTEMS_ENTITY],
          settings: {
            contacts: action.payload.users,
          },
        },
      };

    default:
      return state;
  }
}

export const getIncidentsDateRange = (state) =>
  state.incidents.selected.created || state.incidents.dateRange;
export const getSelectedFilters = (entity = 'incidents', state) =>
  state[entity].selected;
export const getFiltersSettings = (entity = 'incidents', state) =>
  state[entity].settings;
export const getFiltersTagText = (entity = 'incidents', state) =>
  state[entity].filterTagText;
export const getIsShowFiltersPanel = (entity = 'incidents', state) =>
  state[entity].isShow;
export const getOrdering = (entity = 'incidents', state) =>
  state[entity].ordering;
export const getSearch = (entity = 'incidents', state) => state[entity].search;
