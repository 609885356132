import React, { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { Select, Text } from 'combinezone/core';
import { useTranslate, useDebouncedCallback } from 'combinezone/utils';

import { FormElementWrapper, FormElementError } from '../shared';
import { optionItemWithSpinner } from '../shared/helpers';

import { useIssueTypes } from './hooks/useIssueTypes';
import locales from './locales';
import { IssueTypeSelectFormElementProps } from './types';

const IssueTypeSelectFormElement: FC<IssueTypeSelectFormElementProps> = ({
  control,
  error,
  testId,
}) => {
  const t = useTranslate(locales);
  const issueTypeSelectFormElementTestId = `${testId}-form-element-select-issue-type`;

  const { data, isFetching, setInlineSearch, setIsOpen } = useIssueTypes();

  const supplementedOptions = useMemo(() => {
    return isFetching ? [optionItemWithSpinner] : data;
  }, [isFetching, data]);

  const issueTypeSearchHandler = useDebouncedCallback((text: string): void => {
    setInlineSearch(text);
  }, 300);

  return (
    <FormElementWrapper testId={`${issueTypeSelectFormElementTestId}-wrapper`}>
      <Text testId={`${issueTypeSelectFormElementTestId}-title`}>
        {t('issueType')}
      </Text>

      <Controller
        name="issueType"
        control={control}
        rules={{
          required: t('requiredField'),
        }}
        render={({ field }) => (
          <Select
            {...field}
            testId={`${issueTypeSelectFormElementTestId}-select`}
            error={!!error}
            multiple={false}
            options={supplementedOptions}
            onSearchStringChange={issueTypeSearchHandler}
            placeholder={t('issueTypePlaceholder')}
            dropdownProps={{
              onOpen: () => setIsOpen(true),
              onClose: () => setIsOpen(false),
            }}
          />
        )}
      />

      {error && (
        <FormElementError testId={`${issueTypeSelectFormElementTestId}-error`}>
          {error.message}
        </FormElementError>
      )}
    </FormElementWrapper>
  );
};
export default IssueTypeSelectFormElement;

IssueTypeSelectFormElement.displayName = 'IssueTypeSelectFormElement';
